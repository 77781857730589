import React from "react";
import classNames from "classnames";

/**
 * This component is a icon with a tooltip
 * @component
 * @param {string} type The icon identifier
 * @param {string} tooltip The tooltip text to show
 * @param {string} tooltipType The id of the tooltip type
 * @param {string} size The size of the icon (default: 1)
 * @param {string} className Additional classes
 * @returns {JSX.Element} The UI of the component
 * @example <Icon type="info" tooltip="This is a info message" />
 */
const Icon = ({ id, type, tooltip, tooltipType, size = 1, className, style }) => {
    const customTypes = {
        error: "warning",
        sort: "table-sort",
        asc: "sort-desc",
        desc: "sort-asc",
        true: "tick-right",
        false: "remove",
        close: "remove",
        "alert-orange": "alert",
        critical: "warning",
    };

    const fontSize = (size) => {
        const text2rem = {
            xxs: 0.5,
            xs: 0.75,
            sm: 0.875,
            base: 1,
            xl: 1.25,
            "2xl": 1.5,
            "3xl": 1.667,
            "4xl": 2.25,
            "5xl": 3,
            "6xl": 4,
            "7xl": 4.5,
            "8xl": 5,
            "9xl": 5.5,
            "10xl": 6,
        };
        const rem = text2rem[size] || size;
        return `${rem}rem`;
    };

    const iconClass = classNames({
        [`icon icon-${customTypes[type] || type}`]: type,
        "inline-block text-xl lg:text-2xl": true,
        "text-red-500": type === "error",
        "text-red-100": type === "critical",
        "text-orange-100 hover:text-orange-400": type === "warning",
        "text-gray-700 hover:text-gray-800": type === "info",
        "cursor-help": tooltip,
        "hover:text-red-600": type === "error" || type === "delete",
        "text-orange-100 absolute": type === "alert-orange",
        [className]: true,
    });

    const props = {
        id,
        className: iconClass,
        style: {
            fontSize: fontSize(size),
            ...style,
        },
        ...(tooltip && {
            "data-tip": tooltip,
            "data-for": tooltipType || (type === "error" ? "warning-tooltip" : "dangerous-html-tooltip"),
        }),
    };

    if (type === "alert-orange") {
        return (
            <div
                className="relative text-white text-center align-middle font-bold bg-orange-100 rounded-full"
                style={{
                    fontSize: fontSize(size),
                    width: "1.4em",
                    height: "1.4em",
                    lineHeight: "1em",
                    padding: "0.2em",
                    zoom: 0.65,
                }}
            >
                !
            </div>
        );
    }

    return <i {...props} />;
};
Icon.displayName = "Icon";

export default Icon;
