import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "react-apollo";
import { toast } from "react-toastify";
import { gql } from "apollo-boost";

import {
    cleanActiveFilters,
    setCount,
    setCountPosition,
    setCustomConfig,
    setError,
    setHeaderButtons,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setTableType,
    setRefreshData,
} from "actions/tableActions";
import { executeVendureQuery, getVendureApiData } from "hooks/Utils/Integrations/useVendure";
import GetTableHeaders from "hooks/Utils/Table/Headers/GetSettingsTableHeaders";
import GetTableHeaderButton from "hooks/Utils/Table/GetTableHeaderButton";
import TaxZone from "hooks/GraphqlCalls/Settings/models/TaxZone";
import { capitalizeFirst } from "hooks/Utils/Utils";

const ListTaxZones = () => {
    const { t } = useTranslation();

    //Store data
    const { currentPage, perPage, refreshData, activeFilters } = useSelector((state) => state.table);

    //Data
    const sectionName = `list-tax-zones`;

    //States
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_TAX_ZONES = queryTax;

    const [executeQuery, { refetch: refetchZafiroData }] = useLazyQuery(GET_ZAFIRO_SHOPS, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: "true",
        onCompleted: (data) => {
            const shopsData = data?.shops?.results ? data.shops.results : [];
            refetch(shopsData);
        },
        onError(err) {
            console.error(err);
            toast.error(capitalizeFirst("mutation-error"));
        },
    });

    //Listeners
    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            refetchZafiroData();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (items && items.length > 0) {
            let itemsClone = [...items];
            if (activeFilters && activeFilters.search && activeFilters.search !== "") {
                let searchValue = decodeURIComponent(activeFilters.search.toUpperCase());
                itemsClone = itemsClone.filter((itemClone) => {
                    let add = itemClone && itemClone.name && itemClone.name.toUpperCase().includes(searchValue);
                    if (!add) {
                        if (itemClone && itemClone.taxRates && itemClone.taxRates.length > 0) {
                            add =
                                itemClone.taxRates.filter((taxRate) => {
                                    return taxRate.name && taxRate.name.toUpperCase().includes(searchValue);
                                }).length > 0;
                        }
                    }
                    return add;
                });
            }
            setItemsFiltered(itemsClone);
        }
        // eslint-disable-next-line
    }, [activeFilters]);

    useEffect(() => {
        let results = null;
        if (itemsFiltered) {
            const pageStart = Math.min((currentPage - 1) * perPage, itemsFiltered.length);
            const pageEnd = Math.min(pageStart + perPage, itemsFiltered.length);
            results = itemsFiltered.slice(pageStart, pageEnd);
        }
        dispatch(setTableResults(results));
        dispatch(setLoading(false));
        // eslint-disable-next-line
    }, [itemsFiltered, perPage, currentPage]);

    const refetch = async (shopsData) => {
        dispatch(setLoading(true));
        dispatch(setError(null));
        const response = await executeVendureQuery(getVendureApiData(), { queryBody: GET_TAX_ZONES });
        let dataItems = [];
        if (response?.data) {
            //Get data
            dataItems = arrangeData(response.data.zones, response.data.taxRates, shopsData);
            dispatch(setSearchable(true));
            dispatch(setHeaders(GetTableHeaders(sectionName)));
            dispatch(setTableType(sectionName));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
            dispatch(setCount(response.data.zones ? response.data.zones.length : 0));
            dispatch(setCountPosition("table-header"));
            dispatch(setCustomConfig({ pagination: true }));
            dispatch(cleanActiveFilters());
        } else {
            dispatch(setError(response?.error ? response.error : "error"));
        }
        dispatch(setLoading(false));
        setItems(dataItems);
        setItemsFiltered(dataItems);
    };

    //Arrange&Functions
    const arrangeData = (zonesList, taxRatesList, shopsData) => {
        const response = [];
        const taxRates = taxRatesList ? taxRatesList.items : null;
        if (zonesList && zonesList.length > 0) {
            zonesList.forEach((zone) => {
                response.push(
                    TaxZone(
                        zone,
                        taxRates.filter((taxRate) => taxRate.zone.id === zone.id && taxRate.enabled),
                        t,
                        shopsData
                    )
                );
            });
        }
        return response;
    };
};

export default ListTaxZones;

const queryTax = `{
    zones: zones{
        id
        name
    }
    taxRates: taxRates{
        items{
            id
            name
            value
            enabled
            category{
                id
            }
            zone{
                id
            }
        }
    }
}`;

const GET_ZAFIRO_SHOPS = gql`
    query {
        shops {
            results {
                id
                name: nameTranslations {
                    text
                    lang
                }
                projectRef
                defaultTaxZone {
                    id
                }
            }
        }
    }
`;
