import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { changeActionValues } from "../../actions/globalActions";
import UseInputText from "../Table/useInputText";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import UseSelect from "../Inputs/useSelect";
import UseComponentLoading from "../useComponentLoading";
import UseRadio from "../useRadio";
import { executeVendureQuery, getVendureApiData } from "../../hooks/Utils/Integrations/useVendure";
import { toast } from "react-toastify";
import currenciesVendure from "../../hooks/Utils/Integrations/vendure-currencies.json";
// import UseMultiSelectDropdown from "./UseMultiSelectDropdown";
import UseSelectMultipleWithSearchOptionsQuery from "../useSelectMultipleWithSearchOptionsQuery";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { getProjectLangs, getSymbolCurrency } from "../../hooks/Utils/SalesUtils";
import UseSelectWithSearch from "../useSelectWithSearch";

import { GlobalContext } from "contexts/Global";

const UseModalNewShop = ({ index }) => {
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { permissions } = useSelector((state) => state.ui);
    const hasOrdersManagement = permissions?.services?.shopsOrders;
    const { values, validations } = useSelector((state) => state.action);
    const { projectLangs } = useSelector((state) => state.ui);

    const SHOP_NAME = "add-shop-name";
    const SHOP_CURRENCY = "add-shop-currency";
    const SHOP_TAXES_ZONE = "add-shop-taxes-zone";
    const SHOP_TAX_RATE_SELECTED = "add-shop-tax-rate-selected";
    const SHOP_TAX_DEFAULT = "add-shop-tax-default";
    const SHOP_TAX_CATEGORY = "add-shop-tax-category";
    const SHOP_TAX_RATE_SELECTED_VALUE = "add-shop-tax-rate-selected-value";
    const GET_TAX_INFO = `{
        zones {
          id
          name
        }
        taxRates {
          items {
            id
            name
            enabled
            value
            category{
                id
            }
            zone{
                id
            }
          }
        }
      }
      `;

    const GET_DATA_USERS = gql(`query{
        users{
            results{
            id
            email
            managerRole 
            ref
            }
        }
      }`);

    const [executeQuery, { data: dataUsers }] = useLazyQuery(GET_DATA_USERS);

    const idTaxZone = values && values[SHOP_TAXES_ZONE] ? values[SHOP_TAXES_ZONE] : null;

    const [shopName, setShopName] = useState("");
    const [taxesZone, setTaxesZone] = useState(null);
    const [taxRateSelected, setTaxRateSelected] = useState(null);
    const [taxesCategory, setTaxesCategory] = useState(null);
    const [taxZones, setTaxZones] = useState([]);
    const [taxesRate, setTaxesRate] = useState([]);
    const [dataUsersSelect, setDataUsersSelect] = useState([]);
    const [taxesRateData, setTaxesRatesData] = useState([]);
    const [currenciesData, setCurrenciesData] = useState([]);
    const [langDefault, setLangDefault] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchTaxInfo();
        executeQuery();
        setCurrenciesData(arrangeCurrenciesData(currenciesVendure, lang));
        if (data && data.name) {
            setShopName(data.name);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (projectLangs) {
            setLangDefault(getProjectLangs(projectLangs, t).langDefault);
        }
        // eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        if (dataUsers && dataUsers.users && dataUsers.users.results && dataUsers.users.results.length > 0) {
            setDataUsersSelect(arrangeDataUsers(dataUsers.users.results));
        }
    }, [dataUsers]);

    useEffect(() => {
        if (idTaxZone && taxZones.length > 0) {
            const taxesRate = arrangeDataTaxesRate(idTaxZone, taxesRateData);
            setTaxesRate(taxesRate);
            if (taxesRate.length > 0 && data.taxRate) {
                setTaxRateSelected(data.taxRate);
            } else if (taxesRate.length > 0) {
                setTaxRateSelected(taxesRate[0].id);
                setTaxesCategory(taxesRate[0].category.id);
            } else if (taxesRate.length === 0) {
                dispatch(changeActionValues({ [SHOP_TAX_CATEGORY]: null }));
                dispatch(changeActionValues({ [SHOP_TAX_RATE_SELECTED]: null }));
            }
        }
        // eslint-disable-next-line
    }, [idTaxZone, taxZones, taxesRateData]);

    useEffect(() => {
        if (idTaxZone) {
            dispatch(changeActionValues({ [SHOP_TAX_DEFAULT]: idTaxZone }));
        }
        // eslint-disable-next-line
    }, [idTaxZone]);

    useEffect(() => {
        if (taxesZone) {
            dispatch(changeActionValues({ [SHOP_TAX_DEFAULT]: taxesZone }));
        }
        // eslint-disable-next-line
    }, [taxesZone]);

    useEffect(() => {
        if (taxesCategory) {
            dispatch(changeActionValues({ [SHOP_TAX_CATEGORY]: taxesCategory }));
        }
        // eslint-disable-next-line
    }, [taxesCategory]);

    useEffect(() => {
        if (taxRateSelected) {
            dispatch(changeActionValues({ [SHOP_TAX_RATE_SELECTED]: taxRateSelected }));
        }

        if (taxesRate && taxRateSelected) {
            const taxRateSelectedValue = taxesRate.filter((t) => t.id === taxRateSelected);
            if (taxRateSelectedValue.length > 0) {
                dispatch(changeActionValues({ [SHOP_TAX_RATE_SELECTED_VALUE]: taxRateSelectedValue[0]?.value }));
            }
        }
        // eslint-disable-next-line
    }, [taxRateSelected]);

    const fetchTaxInfo = async () => {
        const response = await executeVendureQuery(getVendureApiData(), { queryBody: GET_TAX_INFO });
        if (response && response.data) {
            setTaxesZone(
                response.data.zones instanceof Array &&
                    response.data.zones.length > 0 &&
                    response.data.zones[0] &&
                    response.data.zones[0].id
                    ? response.data.zones[0].id
                    : "0"
            );
            setTaxZones(arrangeDataTaxesZone(response.data.zones ? response.data.zones : []));
            setTaxesRatesData(
                response.data.taxRates && response.data.taxRates.items ? response.data.taxRates.items : []
            );
        } else {
            toast.error(t("mutation-error"));
        }
        setLoading(false);
    };

    const arrangeCurrenciesData = (data, lang) => {
        let arr;
        if (data.currencies) {
            arr = Object.keys(data.currencies).map((val) => {
                return {
                    id: data.currencies[val].code,
                    name:
                        getSymbolCurrency({ currency_code: data.currencies[val].code }) +
                        " " +
                        data.currencies[val][lang],
                };
            });
        } else {
            arr = [];
        }

        return arr;
    };

    const arrangeDataUsers = (arr) => {
        return arr
            .filter((item) => !item.managerRole && !item.ref.startsWith("superuser"))
            .map(({ id, email }) => ({
                id,
                name: email,
                selected: false,
                visible: true,
            }));
    };

    const arrangeDataTaxesZone = (data) => {
        const arr = [];

        data.forEach((element) => {
            arr.push({
                value: element.id,
                text: element.name,
            });
        });

        return arr;
    };

    const arrangeDataTaxesRate = (id, data) => {
        const arr = [];

        data.forEach((item) => {
            if (item.zone && item.zone.id && Number(item.zone.id) === Number(id)) {
                arr.push(item);
            }
        });

        return arr;
    };

    const renderLoading = () => {
        return (
            <div className="absolute bg-white top-0 z-500 left-0 h-full w-full">
                <UseComponentLoading />
            </div>
        );
    };

    return (
        <div className=" w-full relative">
            {loading ? renderLoading() : null}
            <div>
                <span className=" font-bold">{capitalizeFirst(t("name")) + "*"}</span>
                <div className=" flex w-full items-center">
                    <div className="whitespace-no-wrap mr-3 ">
                        {t(`language:${langDefault ? langDefault : "en"}`) + ` (${t("default-language")})`}
                    </div>
                    <div className=" w-full">
                        <UseInputText
                            inputData={{ name: SHOP_NAME }}
                            validation={validations && validations[SHOP_NAME] === false ? false : true}
                            currentValue={shopName}
                            id={SHOP_NAME}
                            key={shopName}
                        />
                    </div>
                </div>
            </div>
            {hasOrdersManagement && (
                <div className=" mt-5 flex flex-col ">
                    <span className=" font-bold">{capitalizeFirst(t("assigned-users"))}</span>
                    <span className=" mb-3">{t("assign-users-so-manage-orders")}</span>

                    <div className=" w-full ">
                        {/* <UseMultiSelectDropdown id="new-shop" options={dataUsersSelect} /> */}
                        <UseSelectMultipleWithSearchOptionsQuery
                            dataSelect={{
                                textStyle:
                                    "text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden",
                                name: "assign-users-shop",
                                optionData: dataUsersSelect,
                                selectPlaceHolder: "select-users",
                                cStyle: "w-full",
                                sort: true,
                                type: "selectMultipleWithSearch",
                                noSelect: true,
                                id: "add-store-select-user",
                            }}
                            key={dataUsersSelect}
                        ></UseSelectMultipleWithSearchOptionsQuery>
                    </div>
                </div>
            )}
            <div className=" mt-8">
                <div className=" font-bold mb-2">{capitalizeFirst(t("currency")) + "*"}</div>
                <div className=" flex w-full items-center">
                    <UseSelectWithSearch
                        key={currenciesData}
                        data={{
                            // id: SHOP_CURRENCY,
                            optionData: currenciesData,
                            noSelect: true,
                            selectedIds: ["EUR"],
                            id: SHOP_CURRENCY,
                            name: SHOP_CURRENCY,
                            oneSelected: true,
                            activeValue:
                                data && data.currency ? data.currency : currenciesData.length > 0 ? "EUR" : null,
                            inputContainerAdjust: "h-10",
                            containerOptionsStyles: { minHeight: "auto", maxHeight: "12rem", overflow: "scroll" },
                            useOptionID: false,
                        }}
                    />
                </div>
            </div>
            <div className=" mt-6">
                <div className=" font-bold mb-2">{capitalizeFirst(t("taxes-zone")) + "*"}</div>
                <div className=" flex w-full items-center">
                    <UseSelect
                        key={taxesZone}
                        selectData={{
                            id: SHOP_TAXES_ZONE,
                            data: taxZones,
                            activeValue: taxZones.length > 0 ? taxZones[0].value : null,
                            inputContainerAdjust: "h-10",
                            containerOptionsStyles: { minHeight: "auto", maxHeight: "12rem", overflow: "scroll" },
                        }}
                    />
                </div>
            </div>
            <div className=" mt-4">
                {taxesRate.length > 0 ? (
                    <div className=" mb-2">{capitalizeFirst(t("available-taxes-select-the-default-taxes")) + ":"}</div>
                ) : null}
                <div className=" overflow-scroll" style={{ minHeight: "auto", maxHeight: "9rem" }}>
                    {taxesRate.map((item) => {
                        return (
                            <div
                                className=" mb-3 cursor-pointer"
                                onClick={() => {
                                    setTaxRateSelected(item.id);
                                    setTaxesCategory(item.category.id);
                                }}
                            >
                                <UseRadio selected={taxRateSelected} value={item.id} />
                                <div className="first-capital ml-6 -mt-1">{`${item.name} : ${item.value}%`}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default UseModalNewShop;
