import React, { useRef, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { CSVLink } from "react-csv";

/**
 * This component is a button with actions
 * @param {JSX.Element} children The content of the button
 * @param {string} id The id of the button
 * @param {string} design The design of the button (blue, blue-outline, gray, dark-blue, red, white, link, classic-link)
 * @param {boolean} disabled The flag to disable the button
 * @param {boolean} readOnly The flag to make the button read-only
 * @param {function} onClick The function to call when the button is clicked
 * @param {string} href The link to redirect when the button is clicked
 * @param {string} to The link to redirect when the button is clicked
 * @param {string} tooltip The text of the tooltip
 * @param {string} tooltipType The type of the tooltip
 * @param {string} className The class name of the button
 * @returns {JSX.Element} The UI of the component
 * @example <Button id="button-id" onClick={handleClick}>Button</Button>
 */
const Button = forwardRef(
    (
        {
            children,
            id,
            action,
            actionData,
            design,
            disabled,
            readOnly,
            onClick,
            className,
            href,
            to,
            tooltip,
            tooltipType,
        },
        ref
    ) => {
        const buttonRef = useRef(null);

        if (!id) {
            throw new Error("The button must have an id");
        }
        if (onClick && typeof onClick !== "function") {
            throw new Error("The onClick prop must be a function");
        }
        if (href && typeof href !== "string") {
            throw new Error("The href prop must be a string");
        }

        useImperativeHandle(ref, () => ({
            focus: () => {
                if (buttonRef.current) {
                    buttonRef.current.focus();
                }
            },
            click: () => {
                if (buttonRef.current) {
                    buttonRef.current.click();
                }
            },
        }));

        const buttonClass = classNames({
            flex: true,
            "space-x-1": !className?.includes("space-x-"),
            "items-center": !className?.includes("items-"),
            [`btn btn-${design} whitespace-no-wrap`]: design,
            "cursor-pointer": !disabled && !readOnly,
            "cursor-default text-opacity-70 opacity-75": disabled || readOnly,
            "btn-disabled": disabled && design && design !== "link" && design !== "classic-link",
            "px-5": design && design !== "link" && design !== "classic-link",
            "underline text-zafiro-600 hover:text-zafiro-400": design === "classic-link",
            [className]: className,
        });

        const buttonProps = {
            id,
            ref: buttonRef,
            className: buttonClass,
            href: disabled || readOnly ? "#" : href,
            onClick: (...args) => {
                if (disabled || readOnly) {
                    return;
                }
                if (onClick) {
                    onClick(...args);
                }
            },
            ...(tooltip
                ? {
                      "data-tip": tooltip,
                      "data-for": tooltipType || "default-tooltip",
                  }
                : {}),
        };

        if (action === "csv") {
            return (
                <CSVLink {...buttonProps} {...actionData}>
                    {children}
                </CSVLink>
            );
        }

        if (to) {
            return (
                <NavLink {...buttonProps} to={to}>
                    {children}
                </NavLink>
            );
        }

        if (href) {
            return (
                <a {...buttonProps} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            );
        }

        return <button {...buttonProps}>{children}</button>;
    }
);
Button.displayName = "Button";

export default Button;
