import React from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
//Utils
import Table from "components/ZafiroTable";
import { useTranslation } from "react-i18next";
import { useAddToGroup, useListRooms } from "hooks/GraphqlCalls/Hotel/Rooms/useListRooms";
import Loading from "components/Loading";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Select from "components/Select";

const Rooms = () => {
    const { t } = useTranslation();
    const { data, loading, filters, refetch } = useListRooms();
    const { open, close } = useModal();

    return (
        <>
            <UseSectionHeader title={["list-of", "rooms"]} value={"demo"} />
            <div className="main-container">
                {!loading ? (
                    <Table
                        id="rooms-list"
                        paginate={true}
                        header={{
                            room: { title: t("room"), sortable: true },
                            group: { title: t("group") },
                            status: { title: t("status"), sortable: true },
                            guest: { title: t("guest") },
                            stay: { title: t("stay"), sortable: true },
                            "devices-notices": { title: t("device-status"), sortable: true },
                        }}
                        cols={["room", "group", "status", "guest", "stay", "devices-notices"]}
                        rows={data}
                        showCount={true}
                        search={true}
                        filters={[
                            {
                                id: "filter-by-floor",
                                title: t("floor"),
                                multiple: true,
                                selectGroup: true,
                                options: filters[0],
                                onFilter: (value, row) =>
                                    value.some((selectedValue) => row?.floorFilter === selectedValue),
                            },
                            {
                                id: "filter-by-status",
                                title: t("status"),
                                options: [
                                    { id: "status-occupied", label: t("occupied"), value: "occupied" },
                                    { id: "status-empty", label: t("empty"), value: "empty" },
                                ],
                                onFilter: (value, row) =>
                                    value === "occupied"
                                        ? row.statusFilter === "occupied"
                                        : row.statusFilter === "empty",
                            },
                            {
                                id: "filter-by-group",
                                title: t("group"),
                                multiple: true,
                                options: filters[1],
                                onFilter: (value, row) =>
                                    value.some((selectedValue) => row?.groupFilter?.includes(selectedValue)),
                            },
                        ]}
                        batchActions={{
                            options: [
                                {
                                    id: "add-to-group",
                                    label: t("add-to-group"),
                                    value: "add-to-group",
                                },
                            ],
                            onChange: (action, rows) => {
                                if (action === "add-to-group") {
                                    open(
                                        <Modal
                                            id="add-to-group"
                                            title={<h1 className="text-2xl font-bold">{t("add-to-group")}</h1>}
                                            minWidth="30rem"
                                            className="w-1/2 p-10"
                                            onKeyPress={{ Enter: close }}
                                        >
                                            <BatchActionsModal rows={rows} filters={filters[1]} refetch={refetch} />
                                        </Modal>
                                    );
                                }
                            },
                        }}
                    />
                ) : (
                    <Loading adjust="section-loading" style={{ height: "50vh" }} />
                )}
            </div>
        </>
    );
};

export default Rooms;

const BatchActionsModal = ({ rows, filters, refetch }) => {
    const [roomsGroupsId, setRoomsGroupsId] = React.useState([]);
    const [roomsId, setRoomsId] = React.useState(rows?.map((room) => room.id));
    const { t } = useTranslation();
    const { close } = useModal();
    const { updateRoomGroup } = useAddToGroup({ roomsGroupsId, roomsId, refetch });
    return (
        <>
            <main>
                <h2 className="my-10 mb-3">{t("rooms-to-add-to-group")}</h2>
                <div className="flex gap-2 mb-5">
                    {rows?.map((room) => {
                        return <p className="px-3 py-1 bg-gray-200">{room?.roomName}</p>;
                    })}
                </div>
                <div>
                    <p>{t("select-group")}</p>
                    <Select
                        id="select-group"
                        label={t("select-group")}
                        options={filters}
                        multiple
                        style="mt-4"
                        onChange={(value) => {
                            const selectedIds = filters
                                .filter((option) => {
                                    return value?.includes(option.value);
                                })
                                .map((option) => option.id);
                            setRoomsGroupsId(selectedIds);
                        }}
                    />
                </div>
            </main>
            <footer className="flex w-full gap-4 mt-10 justify-end">
                <Button id="cancel" design="white" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button id="alert-accept" design="blue" onClick={updateRoomGroup}>
                    {t("add-to-group")}
                </Button>
            </footer>
        </>
    );
};
