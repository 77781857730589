import UsersAssigned from "../modals/UsersAssigned";
import { useTranslation } from "react-i18next";

const dataType = "role";

/**
 *
 * @param role {{id: number, users: {id: string, name: string}[], type: string, name: string, accesses: {name: string}[]}}
 * @param t {(str: string)=>string}
 * @param isCorporate {boolean}
 * @returns {{editData: {name, id, accesses}, executeSectionQuery: boolean, adjust: string, dataType: string, id, type, actions: [{type: string, value, users}], users, mutationParams: {name, id}, info: [{specialWidth: string, value, cellContent: string},{cellAdjust: string, specialWidth: string, value: *, cellContent: string},{specialWidth: string, value: (string), cellContent: string}]}}
 */
const Role = (role, t, isCorporate) => {
    const { name, accesses, id, users, type } = role;

    const listAccesses = accesses.map((access, index) => [index, t(access.name), "bg-zafiro-400"]);
    return {
        dataType,
        id,
        type,
        adjust: "h-auto",
        executeSectionQuery: true,
        actions: [{ type: dataType, value: id, users }],
        editData: { name, accesses, id: id },
        mutationParams: { id, name },
        users,
        info: [
            {
                cellContent: "text",
                value: name,
                specialWidth: "w-3/12",
            },
            {
                cellContent: "labelsTruncate",
                value: listAccesses,
                specialWidth: "w-9/12 pr-6",
                cellAdjust: "truncate labels",
            },
        ],
    };
};

export default Role;
