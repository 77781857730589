import React from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseTable from "../../../Table/";
//Utils
import ListGuests from "../../../../hooks/GraphqlCalls/Customers/ListGuests";
import UpdateBooking from "hooks/GraphqlCalls/Booking/UpdateBooking";
import UseButton from "components/useButton";
import { useDispatch, useSelector } from "react-redux";
import { openModal, setModalContent } from "actions/uiActions";
import { ExportGuestRegistrationForm } from "hooks/GraphqlCalls/Guest/modals/ExportGuestRegistrationForm";
import { useTranslation } from "react-i18next";

const Guests = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);
    const hasPreCheckin = permissions?.guests?.preCheckin;
    ListGuests();

    return (
        <>
            <UpdateBooking></UpdateBooking>
            <div className="flex justify-between">
                <UseSectionHeader title={"hotel-guests"} />
                {hasPreCheckin && (
                    <div className="flex items-end pb-5">
                        <UseButton
                            adjust={"p-4"}
                            buttonColor={"btn-blue"}
                            buttonName={"export-guest-registration-form"}
                            action={() => {
                                dispatch(setModalContent(ExportGuestRegistrationForm(t)));
                                dispatch(openModal());
                            }}
                        ></UseButton>
                    </div>
                )}
            </div>
            <UseTable />
        </>
    );
};

export default Guests;
