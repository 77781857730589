import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";
//Actions
import { showGlobalLoading, closeModal, setModalContent } from "../../../actions/uiActions";
import {
    setLoading,
    setPage,
    cleanSortCriteria,
    cleanActiveFilters,
    setActiveFilters,
    getFilterQuery,
    cleanCheckedItems,
} from "../../../actions/tableActions";
import { setRefreshContentData, setExecuteQuery } from "../../../actions/sectionActions";
import { cleanAction, setActionValidations } from "../../../actions/globalActions";

//Utils
import { toast } from "react-toastify";
import { escapeSpecialChars, removeApolloCacheKeys } from "../../Utils/Utils";
import { createBrowserHistory } from "history";

const UpdateSettings = ({ client }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { checkedItems, results } = useSelector((state) => state.table);

    const actionData = useSelector((state) => state.action);

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");

    //Actions
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(err.message);

            let validations = [];
            validations["starter-room"] = false;
            dispatch(setActionValidations(validations));
            // toast.error(langStrings["mutation-error"]);
        },
    });

    useEffect(() => {
        let projectData;
        let allProperties;
        let usersArray;
        let execute = false;
        if (executeQuery) {
            executeQuery.goBack = false;
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "delete-user":
                    setMutation(`
                    mutation{
                        deleteUser(
                            id:  ["${actionData.itemsAffected[0]}"]
                        ){ error ok }}
                `);
                    executeQuery.mutationName = "deleteUser";
                    executeQuery.cacheKeyToDelete = "roles";
                    break;

                case "delete-role":
                    setMutation(`
                        mutation{
                            deleteCustomRole(
                                id:  "${actionData.itemsAffected[0]}"
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "deleteCustomRole";
                    executeQuery.cacheKeyToDelete = "users";
                    break;

                case "duplicate-custom-role":
                    setMutation(`
                        mutation{
                            duplicateCustomRole(
                                id:  ${executeQuery.params.id}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "duplicateCustomRole";
                    executeQuery.cacheKeyToDelete = "roles";
                    break;

                case "duplicate-predefined-role":
                    setMutation(`
                        mutation{
                            duplicatePredefinedRole(
                                id:  ${executeQuery.params.id}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "duplicatePredefinedRole";
                    executeQuery.cacheKeyToDelete = "roles";
                    break;

                case "generate-password":
                    setMutation(`
                        mutation{
                            batchRegeneratePassword(users:[
                                {
                                ref: "${executeQuery.params.value.ref}"
                                name: "${executeQuery.params.value.name}"
                                email:  "${executeQuery.params.value.email}"
                                }
                            ]
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "batchRegeneratePassword";
                    executeQuery.toastMsg = "password-send";
                    break;

                case "add-manager-role":
                    setMutation(`
                        mutation{
                            createCustomRole(
                                name: "${escapeSpecialChars(actionData.values["role-name"])}"
                                accesses:["${actionData.values["role-accesses"].join('","')}"
                            ]
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "createCustomRole";
                    executeQuery.cacheKeyToDelete = "roles";
                    executeQuery.cleanSortAndFilters = true;
                    break;

                case "edit-role":
                    setMutation(`
                        mutation{
                            updateCustomRole(
                                id:  ${actionData.itemsAffected[0]}
                                name: "${escapeSpecialChars(actionData.values["role-name"])}"
                                accesses:["${actionData.values["role-accesses"].join('","')}"]
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateCustomRole";
                    executeQuery.cacheKeyToDelete = "users";
                    break;

                case "add-manager-user":
                    //for now, the user is added to the current project
                    projectData = "";
                    allProperties =
                        actionData.values["properties"] &&
                        actionData.values["properties"].filter((property) => property === "all").length > 0;
                    if (
                        !allProperties &&
                        actionData.values["properties"] &&
                        actionData.values["properties"].length > 0
                    ) {
                        projectData = actionData.values["properties"].map(function (a) {
                            return `"${a}"`;
                        });
                    }
                    setMutation(`
                        mutation{
                            createUser(
                                fullName: "${escapeSpecialChars(actionData.values["user-name"])}"
                                email: "${escapeSpecialChars(actionData.values["user-email"])}"
                                roles:[${actionData.values["roles"].join(",")}]
                                ${
                                    projectData
                                        ? `
                                projects :[${projectData}]`
                                        : ``
                                }
                                ${allProperties ? `assignedAll : true` : ``}
                                ${
                                    actionData.values["corporateUser"] !== undefined
                                        ? `hasCorporate :${actionData.values["corporateUser"]}`
                                        : ""
                                }                             
                            ){ error ok }}
                    `);

                    executeQuery.mutationName = "createUser";
                    executeQuery.cleanSortAndFilters = true;
                    executeQuery.cacheKeyToDelete = "roles";
                    dispatch(showGlobalLoading(true));
                    break;
                case "edit-user":
                    //for now, the user is added to the current project
                    allProperties = actionData.values["properties"].filter((property) => property === "all").length > 0;

                    projectData = "";
                    if (
                        !allProperties &&
                        actionData.values["properties"] &&
                        actionData.values["properties"].length > 0
                    ) {
                        projectData = actionData.values["properties"].map(function (a) {
                            return `"${a}"`;
                        });
                    }
                    setMutation(`
                        mutation{
                            updateUser(
                                ref: "${executeQuery.params.value.ref}"
                                fullName: "${escapeSpecialChars(actionData.values["user-name"])}"
                                email: "${escapeSpecialChars(actionData.values["user-email"])}"
                                roles:[${actionData.values["roles"].join(",")}]                          
                                projects :[${projectData}]                                
                                assignedAll : ${allProperties ? true : false}
                                replaceProjects: true
                                ${
                                    actionData.values["corporateUser"] !== undefined
                                        ? `hasCorporate :${actionData.values["corporateUser"]}`
                                        : ""
                                }
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateUser";
                    executeQuery.cacheKeyToDelete = "roles";
                    dispatch(showGlobalLoading(true));
                    break;
                case "batch-generate-password":
                    usersArray = [];

                    results.map((result) =>
                        checkedItems.includes(result.mutationParams.id)
                            ? usersArray.push({
                                  ref: `${result.mutationParams.ref}`,
                                  name: `${result.mutationParams.name}`,
                                  email: `${result.mutationParams.email}`,
                              })
                            : null
                    );
                    setMutation(`
                        mutation{
                            batchRegeneratePassword(users:${JSON.stringify(usersArray)
                                .replaceAll('"ref"', "ref")
                                .replaceAll('"name"', "name")
                                .replaceAll('"email"', "email")}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "batchRegeneratePassword";
                    executeQuery.cleanCheckedItems = true;
                    dispatch(showGlobalLoading(true));
                    break;

                case "delete-manager-users":
                    setMutation(`
                        mutation{
                            deleteUser(
                                id:  ["${checkedItems.join('","')}"]
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "deleteUser";
                    executeQuery.cacheKeyToDelete = "roles";
                    executeQuery.cleanCheckedItems = true;
                    dispatch(showGlobalLoading(true));
                    break;

                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].isOk || data[executeQuery.mutationName].ok)))
        ) {
            toast.success(langStrings[executeQuery.toastMsg ? executeQuery.toastMsg : "operation-successful"]);
            if (executeQuery.goBack) {
                const history = createBrowserHistory();
                history.back();
            }
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }

            if (executeQuery.cleanCheckedItems) {
                dispatch(cleanCheckedItems());
            }
            if (executeQuery.cleanSortAndFilters) {
                dispatch(cleanSortCriteria());
                dispatch(cleanActiveFilters());
                dispatch(setActiveFilters(null));
                dispatch(getFilterQuery({}));
                dispatch(setPage(1));
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(showGlobalLoading(false));
            if (!executeQuery.goBack) {
                dispatch(setRefreshContentData(true));
            }

            //remove guest list cache to show updated info
            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error
                    : data[executeQuery.mutationName] && data[executeQuery.mutationName].error
                    ? data[executeQuery.mutationName].error
                    : null;
            toast.error(errorMsg ? errorMsg : langStrings["mutation-error"]);
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateSettings);
