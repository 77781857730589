import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setActionInput } from "actions/globalActions";

import Translations from "components/Translations";

const UseModalEditDescriptionProduct = ({ index }) => {
    const dispatch = useDispatch();
    const { projectLangs } = useSelector((state) => state.ui);
    const modalInfo = useSelector((state) => state.ui.modalContent.inputs[index]);
    const idProduct = modalInfo && modalInfo.data && modalInfo.data.idProduct ? modalInfo.data.idProduct : "";

    const storedTranslations = modalInfo?.data?.objTranslations
        ? Object.keys(modalInfo.data.objTranslations).map((lang) => ({
              languageCode: modalInfo.data.objTranslations[lang].languageCode,
              name: modalInfo.data.objTranslations[lang].description,
          }))
        : null;

    useEffect(() => {
        const dataToAction = {
            "id-product": idProduct,
            translations: storedTranslations
                ? storedTranslations?.map((t) => ({ lang: t.languageCode, value: t.name }))
                : [],
        };
        dispatch(setActionInput(dataToAction));
    }, [idProduct]);

    return (
        <Translations
            id="modal-translations-component"
            languages={projectLangs ? projectLangs.map((lang) => lang.languageRef) : null}
            translations={storedTranslations}
            defaultLang={projectLangs ? projectLangs.find((lang) => lang.isDefault === true)?.languageRef : null}
            multiline={5}
            maxHeight="25rem"
            design="cols"
            onChange={(translations) => {
                dispatch(
                    setActionInput({
                        "id-product": idProduct,
                        translations: translations
                            ? translations?.map((t) => ({ lang: t.languageCode, value: t.name }))
                            : [],
                    })
                );
            }}
        />
    );
};

export default UseModalEditDescriptionProduct;
