import React, { useContext } from "react";
import classNames from "classnames";

import Header from "./Header";
import TableContext from "./Context";
import ErrorInfo from "components/ErrorInfo";
import Data from "components/Data";

const Body = ({ children, height, minHeight, maxHeight, className }) => {
    const [minHeigthClass, minHeightStyle] = deconstructSize(minHeight);
    const [maxHeigthClass, maxHeightStyle] = deconstructSize(maxHeight);
    const [heightClass, heightStyle] = deconstructSize(height);

    const tableClass = classNames({
        "overflow-auto": true,
        [heightClass]: heightClass,
        [minHeigthClass]: minHeigthClass,
        [maxHeigthClass]: maxHeigthClass,
    });
    const tableStyle = {
        height: heightStyle,
        minHeight: minHeightStyle,
        maxHeight: maxHeightStyle,
    };

    return (
        <div className={tableClass} style={tableStyle}>
            <table className="w-full">
                {children || (
                    <>
                        <Header />
                        <TBody className={className} />
                    </>
                )}
            </table>
        </div>
    );
};

const TBody = ({ className }) => {
    const { cols, visibleRows: rows, search, header, isExpanded, toggleExpand, texts } = useContext(TableContext);

    const isSearching = !!search?.length;

    if (!cols?.length || rows === undefined) {
        return (
            <tbody className={className || null}>
                <tr className="text-center">
                    <td colSpan={cols?.length || 1} className="p-4">
                        <ErrorInfo>{cols?.length ? "Rows are missing" : "Columns are missing"}</ErrorInfo>
                    </td>
                </tr>
            </tbody>
        );
    }

    if (!rows?.length) {
        return (
            <tbody className={className || null}>
                <tr className="text-center">
                    <td colSpan={cols?.length || 1} className="px-4 pb-4 pt-10">
                        {isSearching ? texts?.noSearchResults : texts?.noTableData}
                    </td>
                </tr>
            </tbody>
        );
    }

    return (
        <tbody className={className || null}>
            {rows
                ? rows.map((row, index) => {
                      const rowID = row?.id || `row-${index}`;
                      const config = row?.rowConfig;
                      return (
                          <>
                              <tr
                                  id={`row-${rowID}`}
                                  key={rowID}
                                  className={`text-left ${config?.expanded ? "cursor-pointer hover:bg-gray-100" : ""} `}
                                  onClick={
                                      config?.expanded
                                          ? (e) => {
                                                if (e) {
                                                    e.stopPropagation();
                                                }
                                                toggleExpand(rowID);
                                            }
                                          : undefined
                                  }
                              >
                                  {cols?.map((col, index) => {
                                      const td = row?.[col];
                                      const th = header?.[col];

                                      const style = {
                                          width: th?.width || "auto",
                                          minWidth: th?.minWidth || "auto",
                                          maxWidth: th?.maxWidth || "auto",
                                      };

                                      const tdClass = classNames({
                                          "border-b border-gray-200 px-4 py-4": true,
                                          [`text-${th?.align}`]: th?.align,
                                      });
                                      const cellClass = classNames({
                                          "flex items-center space-x-2 break-all": true,
                                          "justify-center": th?.align === "center",
                                          "justify-end": th?.align === "right",
                                          "justify-start": th?.align === "left",
                                      });
                                      return (
                                          <td key={col} className={tdClass} style={style}>
                                              <div className={cellClass}>
                                                  <TableData isExpanded={isExpanded?.(rowID)}>{td}</TableData>
                                              </div>
                                          </td>
                                      );
                                  })}
                              </tr>
                              {config?.expanded && config?.expanded !== true ? (
                                  <tr>
                                      <td colSpan={cols.length} className="p-0">
                                          <div
                                              className={`border-b border-gray-200 px-4 transition-all duration-300 ease-in-out ${
                                                  isExpanded?.(rowID) ? "py-4" : "overflow-hidden h-0 opacity-0"
                                              }`}
                                          >
                                              <TableData>{config.expanded}</TableData>
                                          </div>
                                      </td>
                                  </tr>
                              ) : null}
                          </>
                      );
                  })
                : null}
        </tbody>
    );
};

const TableData = ({ children, isExpanded }) => {
    if (typeof children === "function") {
        return <Data>{children({ expanded: isExpanded })}</Data>;
    }

    return <Data>{children}</Data>;
};

const styleRegex = /^(\d+(\.\d+)?(px|em|rem|vh|vw|%)|auto|inherit|initial|unset)$/;

const deconstructSize = (size) => {
    let className, style;
    if (styleRegex.test(size)) {
        style = size;
    } else {
        className = size;
    }
    return [className, style];
};

export default Body;
