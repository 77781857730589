import React, { cloneElement, useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { forceString } from "hooks/Utils/Utils";

import Dropdown from "components/Dropdown";
import Checkbox from "components/Inputs/Checkbox";
import Search from "components/TextInput/Search";
import Button from "components/Button";
import Icon from "components/Icon";

const Select = forwardRef(
    (
        {
            children,
            id,
            options,
            value,
            placeholder,
            multiple,
            onChange,
            designClass: customDesignClass,
            optionsContainerClass,
            disabled: forceDisabled,
            selectGroup,
            warning,
            search: forceSearch,
            allowUnselect = true, // Allow unselecting the selected option
            width,
        },
        ref
    ) => {
        if (!id) {
            throw new Error("Select component requires an id");
        }

        const bodyRef = useRef(null);

        const { t } = useTranslation();
        const [open, setOpen] = useState(false);
        const [disabled, setDisabled] = useState(forceDisabled);
        const [selected, setSelected] = useState(
            multiple
                ? (Array.isArray(value) ? value : [value])?.filter((v) => v !== null && v !== undefined) || []
                : value
        );
        const someSelected = isSomeSelectedFn(selected, { multiple });

        const [handler, setHandler] = useState(null);
        const [body, setBody] = useState(null);

        const childrenArray = !children
            ? null
            : Array.isArray(children)
            ? children.filter((child) => child)
            : [children];
        const allOptions = options?.length ? options : childrenArray;

        if (options?.length && childrenArray?.length) {
            console.warn("You have options and children, only options will be used", { options, childrenArray });
        }

        const countOptions = allOptions?.length
            ? allOptions.map((o) => o?.options?.length || 1).reduce((a, b) => a + b)
            : 0;

        const showSearch = forceSearch ?? countOptions > 5;

        const changeValue = (value) => {
            return changeValueFn(value, {
                allowUnselect,
                selected,
                onChange,
                setSelected,
            });
        };

        const addValue = (value) => {
            return addValueFn(value, {
                allowUnselect,
                multiple,
                selected,
                onChange,
                setSelected,
                selectGroup,
                allOptions,
            });
        };

        const removeValue = (value) => {
            return removeValueFn(value, {
                multiple,
                selected,
                onChange,
                setSelected,
            });
        };

        useEffect(() => {
            if (multiple) {
                updateMultipleSelectionsFn(selected, allOptions, setSelected, onChange);
            } else {
                updateSingleSelectionFn(selected, allOptions, setSelected, onChange);
            }
        }, [options]);

        useEffect(() => {
            let selectPlaceholder = placeholder || " ";

            const selectedLabels = getSelectedLabelsFn(selected, { multiple, options: allOptions });

            const selectedLabel =
                multiple && selected?.length ? t("x items selected", { count: selected.length }) : selectedLabels?.[0];

            setHandler(selectedLabel || selectPlaceholder);

            setBody(
                allOptions?.length ? (
                    <SelectBody
                        id={id}
                        ref={bodyRef}
                        {...{
                            optionsContainerClass,
                            selected,
                            multiple,
                            someSelected,
                            selectedLabels,
                            allOptions,
                            showSearch,
                            setOpen,
                            changeValue,
                            addValue,
                            removeValue,
                            selectGroup,
                        }}
                    />
                ) : null
            );
        }, [placeholder, options, children, selected, showSearch]);

        useEffect(() => {
            setSelected(
                multiple && value !== null && value !== undefined
                    ? Array.isArray(value) && value?.length
                        ? value
                        : [value]
                    : value
            );
        }, [value]);

        useEffect(() => {
            setDisabled(forceDisabled || !body);
        }, [body, forceDisabled]);

        const dropdownConfig = {
            id,
            showArrow: true,
            designClass: {
                dropdown: classNames({
                    "select-none rounded h-10 inline-block flex items-center justify-center": true,
                    "bg-gray-200": !disabled, // Enabled
                    "bg-gray-300 text-gray-700": disabled, // Disabled
                    "border border-orange-100": warning, // Warning state
                    "font-bold": open, // Open
                    "text-orange-500": open && warning, // Warning state (open)
                    "border border-zafiro-300 text-zafiro-300": open && !warning, // Open
                    "text-gray-600": !disabled && !open && !someSelected, // Showing placeholder (closed)
                    "text-gray-900": !disabled && !open && someSelected, // Showing selected option (closed)
                }),
                handler: classNames({ "truncate first-capital px-4 py-1": true }),
                handlerIcon: "text-gray-900 px-3 py-1",
                ...customDesignClass,
            },
            ref,
            width,
            open,
            setOpen,
            disabled,
            handler,
            body,
            tooltip: warning,
            tooltipType: warning ? "warning-tooltip" : null,
            float: true,
            onOpenChange: (open) => {
                if (bodyRef?.current?.onOpenChange) {
                    bodyRef.current.onOpenChange(open);
                }
            },
        };

        return <Dropdown {...dropdownConfig} />;
    }
);
Select.displayName = "Select";

/**
 * @param id {string}
 * @param disabled {boolean}
 * @param onClick {function}
 * @param href {string}
 * @param className {string}
 * @param children {React.Children}
 * @returns {JSX.Element}
 * @constructor
 */
const SelectOption = ({ id, disabled, multiple, isGroup, onClick, href, className, children, value, selected }) => {
    if (onClick && href) {
        throw new Error("You can't use onClick and href at the same time");
    }

    const hasAction = !disabled && onClick;

    const buttonClass = classNames({
        "w-full py-3 first-capital text-left whitespace-nowrap": true,
        "px-5": !multiple,
        "px-3": multiple,
        "opacity-50": disabled,
        "cursor-default": !hasAction,
        "cursor-pointer hover:text-zafiro-300": hasAction,
        "text-zafiro-400": selected && !isGroup,
        "font-bold": isGroup,
        [className]: className,
    });

    const onClickEvent = (e) => {
        e.stopPropagation();
        if (typeof onClick === "function" && !disabled) {
            onClick(e);
        }
    };

    if (href) {
        return (
            <a id={id} href={disabled ? "#" : href} target="_blank" rel="noopener noreferrer" className={buttonClass}>
                {children}
            </a>
        );
    }

    return (
        <button id={id} onClick={onClickEvent} className={buttonClass || ""}>
            {children}
        </button>
    );
};

const parseOptionFn = (option, selected, { setOpen, multiple, addValue, changeValue, selectGroup }) => {
    if (option && React.isValidElement(option)) {
        return cloneElement(option, {
            selected: isSelectedFn(option.props?.value, { multiple, selected }),
            onClick: (e) => {
                if (e) {
                    e.stopPropagation();
                }
                if (option.props?.disabled) {
                    return;
                }
                if (option.props.onClick) {
                    option.props.onClick(e);
                }
                if (multiple) {
                    addValue(option.props?.value);
                } else {
                    changeValue(option.props?.value);
                    setOpen(false);
                }
            },
        });
    } else if (option && typeof option === "object") {
        const isGroup = option?.options?.length;
        // If is a group, select all options by default
        const currentValue = option?.value ?? (isGroup && multiple ? option.options.map((o) => o.value) : null);
        const toggleOption = (e) => {
            if (e) {
                e.stopPropagation();
            }
            if (isGroup && selectGroup) {
                addValue(currentValue);
            }
            if (option?.disabled) {
                return;
            }
            if (option?.onClick) {
                option.onClick(e);
            }
            if (multiple) {
                addValue(currentValue);
            } else {
                changeValue(currentValue);
                setOpen(false);
            }
        };

        return (
            <>
                <SelectOption
                    key={option?.id}
                    id={option?.id}
                    value={currentValue}
                    multiple={multiple}
                    disabled={option?.disabled}
                    selected={isSelectedFn(currentValue, { multiple, selected })}
                    isGroup={isGroup}
                    onClick={isGroup ? null : toggleOption}
                >
                    {multiple && !isGroup ? (
                        <div className="inline-block mr-2">
                            <Checkbox
                                id={`${option?.id}-checkbox`}
                                checked={isSelectedFn(currentValue, { multiple, selected })}
                                disabled={option?.disabled}
                                onChange={() => {
                                    toggleOption();
                                }}
                            />
                        </div>
                    ) : null}
                    {isGroup && selectGroup ? (
                        <div className="inline-block mr-2">
                            <Checkbox
                                id={`${option?.id}-checkbox`}
                                checked={isSelectedFn(currentValue, { multiple, selected })}
                                disabled={option?.disabled}
                                onChange={() => {
                                    toggleOption();
                                }}
                            />
                        </div>
                    ) : null}
                    {option?.label || option?.name}
                </SelectOption>
                {isGroup ? (
                    <div className={`${selectGroup && "ml-4"}`}>
                        {option.options.map((o) =>
                            parseOptionFn(o, selected, {
                                setOpen,
                                multiple,
                                addValue,
                                changeValue,
                            })
                        )}
                    </div>
                ) : null}
            </>
        );
    } else if (option && typeof option === "string") {
        const currentValue = option;
        const toggleOption = (e) => {
            if (e) {
                e.stopPropagation();
            }
            if (multiple) {
                addValue(currentValue);
            } else {
                changeValue(currentValue);
                setOpen(false);
            }
        };
        return (
            <SelectOption
                key={option}
                id={option}
                value={currentValue}
                multiple={multiple}
                selected={isSelectedFn(currentValue, { multiple, selected })}
                onClick={toggleOption}
            >
                {multiple ? (
                    <div className="inline-block mr-2">
                        <Checkbox
                            id={`${option}-checkbox`}
                            checked={isSelectedFn(currentValue, { multiple, selected })}
                            onChange={() => {
                                toggleOption();
                            }}
                        />
                    </div>
                ) : null}
                {option}
            </SelectOption>
        );
    }
    return null;
};

const addValueFn = (value, { allowUnselect, multiple, selected, onChange, setSelected, selectGroup, allOptions }) => {
    let newValue;
    if (allowUnselect && isSelectedFn(value, { multiple, selected })) {
        newValue = selected?.filter((v) => v !== value);
    } else {
        // Add value to selected array if not already there
        newValue = Array.isArray(value)
            ? value?.concat(selected)
            : selected?.concat(value)
            ? selected?.concat(value)
            : [...(selected || []), value];
        // remove duplicates
    }

    if (selectGroup) {
        const parentOption = allOptions.find((option) =>
            option?.options?.some(
                (o) => ensureOptionFn(o)?.value === value || value?.includes(ensureOptionFn(o)?.value)
            )
        );
        if (parentOption) {
            const groupOptions = parentOption.options.map((o) => ensureOptionFn(o)?.value);
            if (isSelectedFn(value, { multiple, selected })) {
                newValue = selected?.filter((v) => !groupOptions.includes(v));
            }
        }
    }

    const uniqueValues = newValue?.length ? [...new Set(newValue)]?.filter((v) => v !== null && v !== undefined) : null;
    if (onChange) {
        onChange(uniqueValues?.length ? uniqueValues : null);
    }
    setSelected(uniqueValues?.length ? uniqueValues : null);
};

const removeValueFn = (value, { multiple, selected, onChange, setSelected }) => {
    if (isSomeSelectedFn(selected, { multiple })) {
        const newValue = selected.filter((v) => v !== value);
        if (onChange) {
            onChange(newValue?.length ? newValue : null);
        }
        setSelected(newValue?.length ? newValue : null);
        return true;
    }
    return false;
};

const changeValueFn = (value, { allowUnselect, selected, onChange, setSelected }) => {
    const newValue = allowUnselect && value === selected ? null : value;
    if (onChange) {
        onChange(newValue);
    }
    setSelected(newValue);
};

const isSomeSelectedFn = (selected, { multiple }) => {
    if (multiple) {
        return Array.isArray(selected) && selected?.length > 0;
    }
    return selected || selected === 0 || selected === false;
};

const isSelectedFn = (value, { multiple, selected }) => {
    if (isSomeSelectedFn(selected, { multiple })) {
        if (multiple) {
            if (Array.isArray(value)) {
                return value?.every((v) => selected?.includes(v));
            }
            return Array.isArray(selected) && selected?.includes(value);
        }
        return selected === value;
    }
    return false;
};

const labelSearch = (label, search) => {
    const strLabel = forceString(label);
    const strSearch = forceString(search);
    return strLabel?.toLowerCase().includes(strSearch.toLowerCase());
};

const ensureOptionFn = (input) => {
    if (input && React.isValidElement(input)) {
        return {
            value: input.props?.value,
            label: input.props?.children || input.props?.label || input,
            disabled: input.props?.disabled,
        };
    }
    if (typeof input === "object") {
        return input;
    }
    return {
        value: input,
        label: input,
        disabled: false,
    };
};

const getSelectedLabelsFn = (selected, { options, multiple }) => {
    const someSelected = isSomeSelectedFn(selected, { multiple });
    let names = null;

    if (someSelected && options?.length) {
        names = options
            .map((opt) => {
                const option = ensureOptionFn(opt);
                const isGroup = option?.options?.length;
                if (isGroup) {
                    // If is a group, find all selected options
                    if (multiple) {
                        const found = option.options
                            ?.filter((o) => selected?.includes(ensureOptionFn(o)?.value))
                            .map((o) => ensureOptionFn(o));
                        return found?.length ? found : null;
                    } else {
                        return (
                            ensureOptionFn(option.options?.find((o) => ensureOptionFn(o)?.value === selected)) || null
                        );
                    }
                }
                const found = multiple ? selected?.includes(option?.value) : option?.value === selected;
                return found ? option : null;
            })
            ?.filter((option) => option !== null)
            ?.flat(1);
    }

    if (someSelected && !names?.length) {
        console.warn("Selected value not found in options", {
            someSelected,
            names,
            options,
            selected,
        });
    }

    return names;
};

const isValidSelectionFn = (value, options) =>
    options?.some((option) =>
        option?.options ? option.options.some((o) => o.value === value) : option.value === value
    );

const updateMultipleSelectionsFn = (selectedValues, options, setSelectedValues, onChange) => {
    const validSelections = selectedValues?.filter((value) => isValidSelectionFn(value, options));

    if (JSON.stringify(validSelections) !== JSON.stringify(selectedValues)) {
        setSelectedValues(validSelections);
        if (onChange) {
            onChange(validSelections);
        }
    }
};

const updateSingleSelectionFn = (selectedValue, options, setSelectedValue, onChange) => {
    const isValid = isValidSelectionFn(selectedValue, options);

    if (!isValid && selectedValue !== null) {
        setSelectedValue(null);
        if (onChange) {
            onChange(null);
        }
    }
};

const SelectBody = forwardRef(
    (
        {
            id,
            optionsContainerClass,
            selected,
            multiple,
            setOpen,
            allOptions,
            showSearch,
            someSelected,
            selectedLabels,
            changeValue,
            addValue,
            selectGroup,
            removeValue,
        },
        ref
    ) => {
        const searchRef = useRef(null);

        const { t } = useTranslation();

        const [searchValue, setSearchValue] = useState("");

        const parseOption = (option, selected) => {
            return parseOptionFn(option, selected, {
                setOpen,
                multiple,
                addValue,
                changeValue,
                selectGroup,
            });
        };

        const filteredOptions = searchValue?.length
            ? allOptions
                  .map((option) => {
                      const optionLabel = ensureOptionFn(option)?.label;
                      if (labelSearch(optionLabel, searchValue)) {
                          return option;
                      }
                      if (option?.options) {
                          const filteredGroupOptions = option?.options.filter((groupOption) => {
                              const groupOptionLabel = ensureOptionFn(groupOption)?.label;
                              return labelSearch(groupOptionLabel, searchValue);
                          });
                          if (filteredGroupOptions.length) {
                              return { ...option, options: filteredGroupOptions };
                          }
                      }
                      return null;
                  })
                  .filter((option) => option)
            : allOptions;

        useImperativeHandle(ref, () => ({
            onOpenChange: (open) => {
                if (open && searchRef.current) {
                    searchRef.current.focus();
                }
                if (!open) {
                    setSearchValue("");
                }
            },
        }));

        return (
            <div
                className={`absolute min-w-full max-h-30 overflow-y-scroll select-none z-500 mt-1 rounded shadow-lg text-left border border-gray-300 bg-white ${optionsContainerClass}`}
            >
                {showSearch ? (
                    <div className="p-2">
                        <Search
                            ref={searchRef}
                            id={`select-search-${id}`}
                            value={searchValue}
                            className={"w-full"}
                            onChange={setSearchValue}
                        />
                    </div>
                ) : null}
                {multiple && someSelected ? (
                    <div className="p-2">
                        <div className="font-bold text-gray-900 px-2">{t("selected")}:</div>
                        <div className="flex flex-wrap">
                            {selectedLabels?.map((option, index) => (
                                <Button
                                    id={`selected-${index}-remove`}
                                    key={option?.value}
                                    disabled={option?.disabled}
                                    className="bg-blue-200 text-white text-sm rounded px-2 py-1 ml-1 mt-1"
                                    onClick={() => removeValue(option?.value)}
                                >
                                    <div className="flex justify-between items-center">
                                        {option?.label}
                                        <Icon type="remove" className="pl-2" />
                                    </div>
                                </Button>
                            ))}
                        </div>
                    </div>
                ) : null}
                {filteredOptions
                    ? filteredOptions
                          .map((o) => {
                              return parseOption(o, selected);
                          })
                          .filter((option) => option)
                    : null}
            </div>
        );
    }
);

export default Select;
