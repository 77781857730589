import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { openModal, closeModal, setModalContent } from "actions/uiActions";
import { ShopContext } from "contexts/Sales/Shop";
import SettingsCard from "components/SettingsCard";
import Loading from "components/Loading";
import UseButton from "components/useButton";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import UseSelectWithSearch from "components/useSelectWithSearch";
import UseSelect from "components/Inputs/useSelect";
import Icon from "components/Icon";

import { GlobalContext } from "contexts/Global";

const ShopSettingsCurrencyAndTaxes = () => {
    const { t } = useTranslation();

    const { id } = useParams();

    const { lang } = useContext(GlobalContext);

    const { currency, defaultTax, allCurrencies, allTaxes, allTaxZones, updateConfig } = useContext(ShopContext);

    const dispatch = useDispatch();

    const handleCurrencyAndTaxes = () => {
        const close = () => {
            dispatch(setModalContent({}));
            dispatch(closeModal());
        };
        dispatch(
            setModalContent({
                id: id,
                useGlobalLoading: false,
                bodyAdjust: "w-4/12 m-0",
                executeSectionQuery: false,
                inputs: [
                    {
                        type: "component",
                        component: (
                            <EditConfig
                                id={id}
                                allCurrencies={allCurrencies}
                                allTaxes={allTaxes}
                                allTaxZones={allTaxZones}
                                value={{
                                    currencyCode: currency?.code,
                                    defaultTaxZoneId: defaultTax?.zone?.id,
                                    defaultTaxId: defaultTax?.id,
                                    defaultTaxCategoryId: defaultTax?.category?.id,
                                }}
                                onCancel={close}
                                onSave={({ currencyCode, defaultTaxZoneId, defaultTaxId, defaultTaxCategoryId }) => {
                                    return updateConfig({
                                        currencyCode,
                                        defaultTaxZoneId: defaultTaxZoneId,
                                        defaultShippingZoneId: defaultTaxZoneId,
                                        defaultTaxId: defaultTaxId,
                                        defaultTaxCategoryId: defaultTaxCategoryId,
                                    }).then(() => {
                                        close();
                                    });
                                }}
                            />
                        ),
                    },
                ],
            })
        );
        dispatch(openModal());
    };

    const noConfig = (
        <div className="flex items-center space-x-2">
            <Icon type="warning" size="xl" />
            <span>{t("no-data-is-set-yet")}</span>
        </div>
    );

    return (
        <SettingsCard
            title={t("currency-and-taxes")}
            edit={{
                id: "shop-settings-button-open-modal-currency-and-taxes",
                onClick: handleCurrencyAndTaxes,
            }}
        >
            <SettingsCard.Section
                title={t("currency")}
                description={currency ? `${currency.symbol} (${currency.getName(lang)})` : noConfig}
                id="shop-settings-currency"
            />
            <SettingsCard.Section
                id="shop-settings-taxes"
                title={t("taxes-zone")}
                description={defaultTax?.zone?.name || noConfig}
            />
        </SettingsCard>
    );
};

const EditConfig = ({ id, value, allCurrencies, allTaxes, allTaxZones, onSave, onCancel }) => {
    const { t } = useTranslation();

    const [updating, setUpdating] = useState(false);
    const [validate, setValidate] = useState(false);

    const [currencyCode, setCurrencyCode] = useState(value?.currencyCode);
    const [defaultTaxZoneId, setDefaultTaxZoneId] = useState(value?.defaultTaxZoneId);
    const [defaultTaxId, setDefaultTaxId] = useState(value?.defaultTaxId);
    const [defaultTaxCategoryId, setDefaultTaxCategoryId] = useState(value?.defaultTaxCategoryId);

    const taxRates = defaultTaxZoneId && allTaxes ? allTaxes.filter((tax) => tax?.zone?.id === defaultTaxZoneId) : null;

    const validateFields = () => {
        if (!currencyCode || !defaultTaxZoneId || !defaultTaxId) {
            toast.error(t("errors-in-red"));
            return false;
        }
        return true;
    };

    const updateSettings = () => {
        setValidate(true);
        if (updating || !validateFields()) {
            return;
        }
        if (onSave && typeof onSave === "function") {
            setUpdating(true);
            const result = onSave({
                currencyCode,
                defaultTaxZoneId,
                defaultTaxId,
                defaultTaxCategoryId,
            });
            const onError = () => {
                toast.error(t("mutation-error"));
            };
            const onSuccess = () => {
                toast.success(t("operation-successful"));
            };
            if (result instanceof Promise) {
                result
                    .then(onSuccess)
                    .catch(onError)
                    .finally(() => {
                        setUpdating(false);
                    });
            } else {
                if (result) {
                    onSuccess();
                } else {
                    onError();
                }
                setUpdating(false);
            }
        }
    };

    if (updating) {
        return <Loading />;
    }

    return (
        <div className="-my-5">
            <h2 id={id} className="section-title first-capital mb-5">
                {t("currency-and-taxes")} - {t("edit")}
            </h2>
            <div className="pt-3">
                <div className="pb-5 ">
                    <div className="font-bold first-capital">{`${t("currency")} *`}</div>
                </div>
                <div className="pb-5 ">
                    <div className="first-capital -mt-3">{nl2br(t("select-shop-currency"))}</div>
                </div>
                <div className="pb-5 ">
                    <UseSelectWithSearch
                        data={{
                            optionData: allCurrencies
                                ? allCurrencies.map((currency) => ({
                                      id: currency.code,
                                      name: `${currency.name} (${currency.symbol})`,
                                  }))
                                : null,
                            noSelect: true,
                            selectedIds: [currencyCode],
                            id: "shop-currency-code",
                            name: "shop-currency-code",
                            oneSelected: true,
                            inputContainerAdjust: "h-10",
                            optionsAdjust: "mt-11",
                            onChange: (code) => {
                                setCurrencyCode(code);
                            },
                        }}
                    />
                </div>

                <div className="pb-5 ">
                    <div>
                        <div className="font-bold first-capital">{`${t("taxes-zone")} *`}</div>
                    </div>
                </div>
                <div className="pb-5 ">
                    <div className="first-capital -mt-3">{nl2br(t("select-shop-taxes-zone"))}</div>
                    <UseSelect
                        selectData={{
                            id: `shop-currency-tax-zone`,
                            name: "taxZone",
                            placeHolder: "taxes-zone",
                            data: allTaxZones
                                ? allTaxZones.map((tax) =>
                                      tax?.id
                                          ? {
                                                value: tax.id,
                                                text: tax.name,
                                            }
                                          : null
                                  )
                                : [],
                            noTranslateAV: true,
                            containerOptionsAdjust: "overflow-y-auto max-h-64",
                            activeValue: defaultTaxZoneId,
                            handleChange: (id) => {
                                if (allTaxZones && defaultTaxZoneId !== id) {
                                    const defaultTax = allTaxes.filter((t) => t?.zone?.id == id)?.[0];
                                    setDefaultTaxZoneId(id);
                                    setDefaultTaxId(defaultTax?.id);
                                    setDefaultTaxCategoryId(defaultTax?.category?.id);
                                }
                            },
                        }}
                    />
                    {taxRates && taxRates.length > 0 ? (
                        <div className={`pt-5 ${validate && !defaultTaxId ? "text-red-100" : ""}`}>
                            <div>{t("select-shop-default-tax")}</div>
                            <div className="w-full pt-3 max-h-18 overflow-auto">
                                {taxRates.map((taxRate) => (
                                    <div className="w-full flex items-center mb-2" key={taxRate.id}>
                                        <Radiobutton
                                            id={`select-tax-rate-${taxRate.id}`}
                                            checked={defaultTaxId === taxRate.id}
                                            onChange={({ checked, value }) => {
                                                if (checked) {
                                                    setDefaultTaxId(value?.id);
                                                    setDefaultTaxCategoryId(value?.category?.id);
                                                }
                                            }}
                                            label={`${taxRate.name}: ${taxRate.value}%`}
                                            value={taxRate}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div id="modal-buttons" className={`pt-8 text-center flex justify-end`}>
                {onCancel ? (
                    <UseButton
                        buttonName={"cancel"}
                        buttonColor="btn-white"
                        id={`modal-button-cancel`}
                        action={onCancel}
                    />
                ) : null}
                <UseButton
                    buttonName={"save"}
                    buttonColor="btn-blue"
                    id={`modal-button-save`}
                    action={updateSettings}
                    customClass="ml-4"
                />
            </div>
        </div>
    );
};

const nl2br = (str) => {
    return str.split("\n").map((item) => {
        return (
            <>
                {item}
                <br />
            </>
        );
    });
};

export default ShopSettingsCurrencyAndTaxes;
