import React, { useContext, useState, forwardRef } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { openModal, closeModal, setModalContent } from "actions/uiActions";
import { ShopContext } from "contexts/Sales/Shop";
import SettingsCard from "components/SettingsCard";
import Loading from "components/Loading";
import UseButton from "components/useButton";
import { Radiobutton } from "components/Inputs/Radiobuttons";

const ShopSettingsDeliveryHour = () => {
    const { t } = useTranslation();

    const { deliverySchedule, updateConfig } = useContext(ShopContext);

    const { id } = useParams();

    const dispatch = useDispatch();

    const handleDeliveryHour = () => {
        const close = () => {
            dispatch(setModalContent({}));
            dispatch(closeModal());
        };

        dispatch(
            setModalContent({
                id: id,
                useGlobalLoading: false,
                bodyAdjust: "w-4/12 m-0",
                executeSectionQuery: false,
                inputs: [
                    {
                        type: "component",
                        component: (
                            <EditConfig
                                id={id}
                                value={deliverySchedule}
                                onCancel={close}
                                onSave={(value) => {
                                    return updateConfig({ deliverySchedule: value }).then(() => {
                                        close();
                                    });
                                }}
                            />
                        ),
                    },
                ],
            })
        );
        dispatch(openModal());
    };

    return (
        <SettingsCard
            title={t("delivery-hour")}
            edit={{
                id: `shop-settings-button-open-modal-delivery-hour`,
                onClick: handleDeliveryHour,
            }}
            description={t("select-ranges-client-will-receive-order")}
        >
            <span className="block pb-5 text-base px-4">
                {Array.isArray(deliverySchedule) && deliverySchedule?.length > 0
                    ? deliverySchedule.map((item, index) => (
                          <span
                              key={item?.startTime}
                              id={`shop-settings-delivery-hour-${index}`}
                              className="block mb-4"
                          >
                              {item?.startTime} - {item?.endTime}
                          </span>
                      ))
                    : t("24-hours-delivery")}
            </span>
        </SettingsCard>
    );
};

const EditConfig = ({ id, value, onSave, onCancel }) => {
    const { t } = useTranslation();

    const [updating, setUpdating] = useState(false);
    const [validate, setValidate] = useState(false);
    const [deliverySchedule, setDeliverySchedule] = useState(value);

    const validateField = (index, field) => {
        const item = deliverySchedule ? deliverySchedule?.[index] : null;
        const prevItem = index > 0 && deliverySchedule ? deliverySchedule?.[index - 1] : null;
        if (!item) {
            return false;
        }
        const start = item?.startTime ? new Date(new window.ZDate("2000-01-01T" + item.startTime).toDate()) : null;
        const prevEnd = prevItem?.endTime
            ? new Date(new window.ZDate("2000-01-01T" + prevItem.endTime).toDate())
            : null;
        if (prevItem) {
            // Check if start time is after previous end time
            if (start && prevEnd && start.getTime() <= prevEnd.getTime()) {
                return false;
            }
        }

        switch (field) {
            case "startTime":
                return !!start;
            case "endTime":
                const end = item?.endTime ? new Date(new window.ZDate("2000-01-01T" + item.endTime).toDate()) : null;
                return end && ((start && end.getTime() > start.getTime()) || !start);
            default:
                return true;
        }
    };

    const validateFields = () => {
        if (deliverySchedule) {
            if (deliverySchedule.length < 1) {
                toast.error(t("error-add-at-least-one-option"));
                return false;
            }
            // Check if all fields are valid for custom delivery schedule
            const isValid = deliverySchedule.every((item, index) => {
                return validateField(index, "startTime") && validateField(index, "endTime");
            });
            if (!isValid) {
                toast.error(t("errors-in-red"));
                return false;
            }
        }
        return true;
    };

    const updateSettings = () => {
        setValidate(true);
        if (updating || !validateFields()) {
            return;
        }
        if (onSave && typeof onSave === "function") {
            setUpdating(true);
            const result = onSave(deliverySchedule);
            const onError = (error) => {
                toast.error(t("mutation-error"));
            };
            const onSuccess = (result) => {
                toast.success(t("operation-successful"));
            };
            if (result instanceof Promise) {
                result
                    .then(onSuccess)
                    .catch(onError)
                    .finally(() => {
                        setUpdating(false);
                    });
            } else {
                if (result) {
                    onSuccess();
                } else {
                    onError();
                }
                setUpdating(false);
            }
        }
    };

    const addScheduleItem = () => {
        setDeliverySchedule([...deliverySchedule, { startTime: null, endTime: null }]);
    };

    const removeScheduleItem = (index) => {
        if (deliverySchedule?.length > 0) {
            setDeliverySchedule([...deliverySchedule.slice(0, index), ...deliverySchedule.slice(index + 1)]);
        } else {
            console.error("Error removing schedule item: index out of bounds", { index });
        }
    };

    const updateScheduleItem = (index, field, value) => {
        if (deliverySchedule?.length >= index) {
            setDeliverySchedule([
                ...deliverySchedule.slice(0, index),
                { ...deliverySchedule[index], [field]: value },
                ...deliverySchedule.slice(index + 1),
            ]);
        } else {
            console.error("Error updating schedule item: index out of bounds", { deliverySchedule, index });
        }
    };

    if (updating) {
        return <Loading />;
    }

    return (
        <div className="-my-5">
            <h2 id={id} className="section-title first-capital mb-5">
                {t("delivery-hour")} - {t("edit")}
            </h2>
            <div className="pt-3">
                <div className="pb-5 ">
                    <div>
                        <div className="font-bold first-capital">{t("shop-opening-hours")}</div>
                    </div>
                </div>
                <div className="pb-5 ">
                    <div>
                        <div className="first-capital -mt-3">{t("delivery-hour-description")}</div>
                    </div>
                </div>
                <div className="pb-5 ">
                    <div className="w-full">
                        <div className="w-full flex items-center mb-2">
                            <Radiobutton
                                id="set-24-hours-delivery"
                                checked={!deliverySchedule}
                                onChange={({ checked, value }) => {
                                    setDeliverySchedule(checked ? value : null);
                                }}
                                label={t("24-hours-delivery")}
                                value={null}
                            />
                        </div>
                        <div className="w-full flex items-center mb-2">
                            <Radiobutton
                                id="set-custom-delivery-time"
                                checked={!!deliverySchedule}
                                onChange={({ checked, value }) => {
                                    setDeliverySchedule(checked ? value : null);
                                }}
                                label={t("customise-timetable")}
                                value={[]}
                            />
                        </div>
                        {deliverySchedule ? (
                            <div className="pt-1">
                                {deliverySchedule.map((item, index) => {
                                    const start = item?.startTime
                                        ? new Date(new window.ZDate("2000-01-01T" + item.startTime).toDate())
                                        : null;
                                    const end = item?.endTime
                                        ? new Date(new window.ZDate("2000-01-01T" + item.endTime).toDate())
                                        : null;

                                    const isStartValid = !validate || validateField(index, "startTime");
                                    const isEndValid = !validate || validateField(index, "endTime");

                                    return (
                                        <div className="w-full flex items-center text-center my-3 pl-8">
                                            <div className="w-3/12">
                                                <div className="flex w-full justify-center items-center">
                                                    <DatePicker
                                                        selected={start}
                                                        onChange={(value) => {
                                                            updateScheduleItem(
                                                                index,
                                                                "startTime",
                                                                parseDateHour(value)
                                                            );
                                                        }}
                                                        dateFormat="HH:mm"
                                                        timeCaption={t("Hour")}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={10}
                                                        name={`startTime_${index}`}
                                                        isClearable={false}
                                                        customInput={
                                                            <HourInput
                                                                className={`${
                                                                    !isStartValid ? "border border-red-100" : ""
                                                                }`}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-1/12">
                                                <div className="flex items-center h-full">
                                                    <span className="w-full flex items-center justify-center text-center">
                                                        -
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-3/12">
                                                <div className="flex w-full justify-center items-center">
                                                    <DatePicker
                                                        selected={end}
                                                        onChange={(value) => {
                                                            updateScheduleItem(index, "endTime", parseDateHour(value));
                                                        }}
                                                        dateFormat="HH:mm"
                                                        timeCaption={t("Hour")}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={10}
                                                        name={`endTime_${index}`}
                                                        isClearable={false}
                                                        customInput={
                                                            <HourInput
                                                                className={`${
                                                                    !isEndValid ? "border border-red-100" : ""
                                                                }`}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="px-5">
                                                <span
                                                    id={`delete-timetable-option-${index}`}
                                                    className="icon icon-delete text-xl cursor-pointer"
                                                    onClick={() => removeScheduleItem(index)}
                                                ></span>
                                            </div>
                                        </div>
                                    );
                                })}
                                {deliverySchedule.length < 4 ? (
                                    <div className="w-full flex items-center pt-2 ml-8">
                                        <button
                                            id="add-timetable-option"
                                            className={`text-zafiro-400 font-bold cursor-pointer`}
                                            onClick={addScheduleItem}
                                        >
                                            <span className="icon icon-add mr-2"></span>
                                            {t("add-option")}
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div id="modal-buttons" className={`pt-8 text-center flex justify-end`}>
                {onCancel ? (
                    <UseButton
                        buttonName={"cancel"}
                        buttonColor="btn-white"
                        id={`modal-button-cancel`}
                        action={onCancel}
                    />
                ) : null}
                <UseButton
                    buttonName={"save"}
                    buttonColor="btn-blue"
                    id={`modal-button-save`}
                    action={updateSettings}
                    customClass="ml-4"
                />
            </div>
        </div>
    );
};

const HourInput = forwardRef(({ value, onClick, onChange, onFocus, className }, ref) => {
    return (
        <div id="input_custom_time" className={className || ""}>
            <input
                type="time"
                className={`t-filter-input text-center inline-block py-2 px-3 w-full`}
                onClick={onClick}
                onKeyPress={onClick}
                onFocus={onFocus}
                value={value}
                onChange={onChange}
                tabIndex={0}
                ref={ref}
            />
        </div>
    );
});

const parseDateHour = (value) => {
    const padFix = (n) => {
        return ("00" + n).match(/\d{2}$/);
    };
    let response = "";
    if (value) {
        response = padFix(value.getHours()) + ":" + padFix(value.getMinutes());
    }
    return response;
};

export default ShopSettingsDeliveryHour;
