import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//MODELS
import Shop from "./models/Shop";
import { useTranslation } from "react-i18next";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetSalesTablesHeaders from "../../Utils/Table/Headers/GetSalesTableHeaders";
import {
    setAvailableFilters,
    setHeaderButtons,
    setCountPosition,
    setItemsPerpage,
    setTableResults,
    setBatchActive,
    setRefreshData,
    setCustomError,
    setSearchable,
    setCheckable,
    setLoading,
    setActions,
    setHeaders,
    setCount,
    setError,
    setCustomConfig,
    cleanCheckedItems,
} from "../../../actions/tableActions";
import { Session } from "../../Utils/Session";
import { POLLINTERVAL_15S } from "../../Utils/Utils";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { getProjectLangs, parseTranslation } from "../../Utils/SalesUtils";
import { useSalesModalZafiroTranslations } from "components/Section/Services/Sales/modals/useSalesModalZafiroTranslations";
import { TYPE_QUERY } from "components/Section/Services/Sales/utils/QueriesTranslations";

const ListShop = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currentPage, perPage, sortCriteria, refreshData, activeFilters } = useSelector((state) => state.table);
    const { permissions } = useSelector((state) => state.ui);
    const { projectLangs } = useSelector((state) => state.ui);
    const hasOrdersManagement = permissions?.services?.shopsOrders;

    const { open: openModalEditName } = useSalesModalZafiroTranslations();

    const search = activeFilters?.search ? activeFilters.search : "";
    const state = activeFilters?.state ? activeFilters.state : "";

    const GET_ZAFIRO_SHOPS = gql`
        query {
            shops(page: ${currentPage} , size: ${perPage}
                ${sortCriteria ? sortCriteria : ""}
                ${getCurrentFilter(state, search)}
                ) {
                info {
                    count
                    page
                    size
                }
                results {
                    id
                    name: nameTranslations {
                        lang
                        text
                    }
                    token
                    users
                    customFields {
                        isActive
                    }
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_ZAFIRO_SHOPS, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    const SECTION_NAME = "list-shops";

    //Here
    const [langDefault, setLangDefault] = useState("en");

    useEffect(() => {
        executeQuery();
        dispatch(setItemsPerpage(9));
        Session.setSessionProp("idShop", null);
        dispatch(setLoading(true));
        dispatch(setAvailableFilters(GetAvailableFilters(SECTION_NAME)));
        dispatch(setHeaders(GetSalesTablesHeaders(SECTION_NAME, { hasOrdersManagement })));
        dispatch(setCustomError(t("no-shops-yet")));
        dispatch(setCountPosition("table-header"));
        dispatch(setBatchActive(BatchActions()));
        dispatch(setSearchable(true));
        dispatch(setCheckable(true));
        dispatch(
            setCustomConfig({
                pagination: true,
            })
        );
        dispatch(setActions(true));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (permissions?.services?.shopsAdministrator) {
            dispatch(setHeaderButtons(GetTableHeaderButton(SECTION_NAME, {}, null, t)));
        }
    }, [permissions]);

    useEffect(() => {
        if (projectLangs) {
            const { langDefault } = getProjectLangs(projectLangs, t);
            setLangDefault(langDefault);
        }
        // eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        if (!loading && data?.shops?.results) {
            const tableResults = arrangeData(
                data.shops.results,
                t,
                langDefault,
                hasOrdersManagement,
                openModalEditName
            );
            dispatch(setTableResults(tableResults));
            dispatch(setLoading(false));
            Session.setSessionProp("vendure-shops", JSON.stringify(tableResults));
            dispatch(setCount(data.shops.info.count));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(setError(error, !!error));
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        if (sortCriteria === "" && data) {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 800);
        }
        //eslint-disable-next-line
    }, [sortCriteria]);

    useEffect(() => {
        if (refreshData && refetch) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
            dispatch(cleanCheckedItems());
        }
        // eslint-disable-next-line
    }, [refreshData]);

    return null;
};

export default ListShop;
const BatchActions = () => {
    return [
        {
            name: "delete",
            action: "delete-shops-batch",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "delete-shops-confirm",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "delete", style: "red", action: "delete-shops-batch" },
            ],
        },
        {
            name: "set-as-available",
            action: "set-as-available-shops-batch",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "set-as-available-shops-confirm",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "accept", style: "blue", action: "set-as-available-shops-batch" },
            ],
        },
        {
            name: "set-as-unavailable",
            action: "set-as-unavailable-shops-batch",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "set-as-unavailable-shops-confirm",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "accept", style: "blue", action: "set-as-available-shops-batch" },
            ],
        },
    ];
};

const arrangeData = (data, t, langDefault, hasOrderPermission, openModalEditName) => {
    const shops = [];
    const newData = data.map((s) => {
        return {
            id: s.id,
            name: parseTranslation(s.name, langDefault),
            users: s.users.length,
            available: s.customFields.isActive,
            token: s.token,
            openModalEditName: () => {
                openModalEditName({
                    VARIABLES: { id: s.id, token: s.token },
                    QUERY: TYPE_QUERY.SHOP,
                    title: "edit-name",
                });
            },
        };
    });
    if (newData) {
        newData.forEach((dataItem) => {
            shops.push(Shop(dataItem, t, hasOrderPermission));
        });
    }
    return shops;
};

const getCurrentFilter = (state, search) => {
    let filter = "";

    if (search || state) {
        filter = "filter:{ ";
        if (search) {
            filter += `search: "${search}" , `;
        }
        if (state) {
            filter += `state: ${state}, `;
        }
        filter += "}";
    }

    return filter;
};
