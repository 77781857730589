import React from "react";

import { Component, ListComponents } from "sections/playground";

import Button from "components/Button";
import Icon from "components/Icon";
import { useAlert } from "components/Modal";
import DropdownButton, { Option } from "components/DropdownButton";

const Buttons = () => {
    const { open: alert } = useAlert();

    const buttonDesigns = ["", "link", "blue", "red", "gray", "white", "dark-blue", "blue-outline"];

    return (
        <ListComponents>
            <Component title="Button" className="grid grid-cols-2 gap-4">
                {buttonDesigns.map((design) => (
                    <>
                        <Button
                            id={`btn-${design || "without-design"}`}
                            design={design}
                            onClick={() => alert(`Button ${design || "without design"}`)}
                            className="m-1"
                        >
                            Button {design || "without design"}
                        </Button>
                        <Button
                            id={`btn-${design || "without-design"}-disabled`}
                            design={design}
                            disabled={true}
                            className="m-1"
                        >
                            Disabled {design || "without design"}
                        </Button>
                    </>
                ))}

                <Button id="btn-icon-custom" design="blue">
                    <Icon type="user" />
                </Button>
                <Button id="btn-icon-del" design="link" className="hover:text-red-500">
                    <Icon type="delete" />
                </Button>
                <Button id="btn-icon-custom2" design="link">
                    <Icon type="category" />
                </Button>
                <Button id="btn-icon-custom3" design="white">
                    <Icon type="media" />
                    <span>{"Left icon"}</span>
                </Button>
                <Button id="btn-icon-custom4" design="red">
                    <span>{"Right icon"}</span>
                    <Icon type="alarm" />
                </Button>
                <Button id="btn-icon-custom5" design="blue-outline">
                    <Icon type="product" />
                    <span>{"Multiple icons"}</span>
                    <Icon type="rooms" />
                </Button>
            </Component>

            <DropdownButton
                id="dropdown-button"
                label="Select any"
                options={[
                    {
                        id: "ddb-1",
                        label: "Option 1",
                        onClick: () => alert("Option 1"),
                    },
                    {
                        id: "ddb-2",
                        label: "Option 2",
                        onClick: () => alert("Option 2"),
                    },
                    {
                        id: "ddb-3",
                        label: "Option 3",
                        onClick: () => alert("Option 3"),
                    },
                ]}
            />
            <DropdownButton
                id="dropdown-button-2"
                label={{
                    id: "ddb-default",
                    label: "Default option",
                    onClick: () => alert("Default option"),
                }}
                design="blue-outline"
                options={[
                    {
                        id: "ddb-a",
                        label: "Option A",
                        onClick: () => alert("Option A"),
                    },
                    {
                        id: "ddb-b",
                        label: "Option B",
                        onClick: () => alert("Option B"),
                    },
                    {
                        id: "ddb-c",
                        label: "Option C",
                        onClick: () => alert("Option C"),
                    },
                ]}
            />
            <DropdownButton
                id="dropdown-button-3"
                design="blue"
                label={{
                    id: "ddb-uniq",
                    label: "Unique option",
                    onClick: () => alert("Unique option"),
                }}
            />
            <DropdownButton
                id="dropdown-button-4"
                label={
                    <Button key="ddb-1" id="ddb-1">
                        Custom options
                    </Button>
                }
            >
                <Option key="ddb-2" id="ddb-2" className="bg-gray-800 hover:bg-black text-white">
                    Black option
                </Option>
                <Button design="red" key="ddb-3" id="ddb-3" className="m-2">
                    Consecuter elit
                </Button>
                <Button id="cast-link" design="blue-outline" className="mx-2 mb-2">
                    <Icon type="stats-cast" />
                </Button>
                <div
                    key="ddb-5"
                    id="ddb-5"
                    className="py-1 px-5 text-purple-600 hover:bg-purple-200 text-xs font-bold border border-dashed border-purple-400 cursor-pointer"
                >
                    Lorem ipsum
                </div>
                <Option key="ddb-6" id="ddb-6">
                    Regular item
                </Option>
            </DropdownButton>
        </ListComponents>
    );
};

export default Buttons;
