export const TYPE_QUERY = {
    SHOP: "SHOP",
    PRODUCT: "PRODUCT",
    COLLECTION: "COLLECTION",
    EDIT_LABEL: "EDIT_LABEL",
    CREATE_LABEL: "CREATE_LABEL",
};

export const QUERIES_TRANSLATIONS = {
    [TYPE_QUERY["SHOP"]]: `query shops($token: String!) {
        shops(token: $token) {
            results {
                nameTranslations {
                    lang
                    text
                    __typename
                }
            }
        }
    }`,

    [TYPE_QUERY["PRODUCT"]]: `query GetProductTranslations($id: ID!) {
        product(id: $id) {
            translations {
                id
                languageCode
                name
                slug
                description
            }
        }
    }`,
    [TYPE_QUERY["COLLECTION"]]: `query GetCollectionTranslations($id: ID!) {
        collection(id: $id) {
            translations {
                id
                languageCode
                name
            }
        }
    }`,
    [TYPE_QUERY["EDIT_LABEL"]]: `query GetFacets {
        facets(options: { filter: { code: { contains: "zafiro_labels" } } }) {
            items {
                id
                code
                values {
                    id
                    code
                    translations {
                        id
                        languageCode
                        name
                    }
                }
            }
        }
    }`,
};

export const MUTATION_TRANSLATIONS = {
    [TYPE_QUERY["SHOP"]]: `mutation UpdateShopName($id: Int64!, $name: [TranslateString]!) {
        updateShop(
            id: $id,
            name: $name
        ) {
            id
            error
            ok
        }
    }`,
    [TYPE_QUERY["PRODUCT"]]: `mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
        id
        name
        translations {
            id
            name
            languageCode
        }
    }
    }`,
    [TYPE_QUERY["COLLECTION"]]: `mutation UpdateCollectionTranslations($input: UpdateCollectionInput!) {
        updateCollection(input: $input) {
            id
            translations {
                id
                languageCode
                name
            }
        }
    }`,
    [TYPE_QUERY["CREATE_LABEL"]]: `mutation CreateFacetValues($input: [CreateFacetValueInput!]!) {
    createFacetValues(input: $input) {
      id
    }
  }`,
    [TYPE_QUERY["EDIT_LABEL"]]: `mutation UpdateFacetValues($input: [UpdateFacetValueInput!]!) {
    updateFacetValues(input: $input) {
      id
    }
  }`,
};

export const getDefaultLang = (projectLangs) => {
    return projectLangs?.find((lang) => lang.isDefault)?.languageRef;
};

export const getAllLangs = (projectLangs) => {
    return projectLangs.map((p) => p.languageRef);
};

export const getOtherLangs = (projectLangs) => {
    return projectLangs?.filter((lang) => !lang.isDefault).map((lang) => lang.languageRef);
};

export const handleTranslationsChange = (
    allLangs,
    newTranslations,
    setTranslations,
    includesSlug,
    includesDescription,
    translations
) => {
    const updatedTranslations = addMissingTranslations(allLangs, translations);

    const finalTranslations = updatedTranslations.map((t) => {
        const matchingNewTranslation = newTranslations.find((nt) => nt.languageCode === t.languageCode);

        let modifiedTranslation = {
            ...t,
            name: matchingNewTranslation ? matchingNewTranslation.name : "",
        };

        if (includesSlug) {
            modifiedTranslation.slug = t.slug ? t.slug : t.languageCode;
        }

        if (includesDescription) {
            modifiedTranslation.description = t.description ? t.description.replace(/\n/g, "\\n") : "";
        }

        return modifiedTranslation;
    });

    setTranslations(finalTranslations);
};

const addMissingTranslations = (allLangs, newTranslations) => {
    const existingLangCodes = newTranslations.map((translation) => translation.languageCode);
    const missingTranslations = allLangs
        .filter((lang) => !existingLangCodes.includes(lang))
        .map((lang) => ({ languageCode: lang, name: "" }));

    return [...newTranslations, ...missingTranslations];
};

export const getDefaultTranslation = (translations, defaultLang) => {
    const defaultTranslation = translations.find((t) => t.languageCode === defaultLang);
    return defaultTranslation ? defaultTranslation.name : "";
};
