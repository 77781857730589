import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Label from "hooks/GraphqlCalls/Sales/models/Label";
import { executeVendureQuery, getVendureApiData } from "hooks/Utils/Integrations/useVendure";
import { getProjectLangs } from "hooks/Utils/SalesUtils";
import { useSalesModalVendureTranslations } from "components/Section/Services/Sales/modals/useSalesModalVendureTranslations";

const labelsPrefix = "zafiro_labels";

const queryLabels = `query{
      facets(options: { filter: { code: { contains: "${labelsPrefix}" } } }) {
        items {
          id
          code
          values {
            id
            code
            translations {
              id
              languageCode
              name
            }
          }
        }
      }
  }`;

const useShopLabels = ({ token }) => {
    const { t } = useTranslation();
    const { projectLangs } = useSelector((state) => state.ui);
    const { langDefault } = getProjectLangs(projectLangs, t);
    const { open: openModalVendureTranslations } = useSalesModalVendureTranslations();

    const [called, setCalled] = useState(false);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const load = () => {
        setCalled(true);
        setLoading(true);
        setError(null);
        setData(null);
        if (!token) {
            setLoading(false);
            setError("Undefined token");
            return;
        }
        const response = executeVendureQuery(
            getVendureApiData(),
            { queryBody: queryLabels },
            { "vendure-token": token }
        );
        response
            .then((response) => {
                if (response?.error) {
                    throw new Error(response.error);
                }
                setLoading(false);

                let labels = [];
                let facetID = null;
                if (response?.data?.facets?.items?.length > 0) {
                    response.data.facets.items.forEach((facet) => {
                        if (facet.code === `${labelsPrefix}_${token}`) {
                            facetID = facet.id;
                            let facetValues = facet.values;
                            if (facetValues && facetValues.length > 0) {
                                facetValues.forEach((label) => {
                                    labels.push(
                                        Label(label, projectLangs, langDefault, facet.id, openModalVendureTranslations)
                                    );
                                });
                            }
                        }
                    });
                }
                setData({ labels, facetID });
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    return {
        called: called,
        load: load,
        loading: loading,
        error: error,
        ready: called && !loading && !error,
        data: data,
        refetch: load,
    };
};

export default useShopLabels;
