import DeleteLabel from "../modals/DeleteLabel";
import NewLabel from "../modals/NewLabel";
import i18n from "i18n";
import { TYPE_QUERY } from "components/Section/Services/Sales/utils/QueriesTranslations";

const Label = (label, projectLangs, portalLang, facetId, openModalVendureTranslations) => {
    const dataType = "label";
    let name = label.name && label.name !== "" ? label.name : null;
    let translations = [];
    if (label.translations && label.translations.length > 0) {
        let translation = label.translations.filter((translation) => translation.languageCode === portalLang);
        if (translation.length > 0) name = translation[0].name;
    }
    if (label.translations && label.translations.length > 0) {
        label.translations.forEach((translation) => {
            translations.push({
                id: translation.id,
                isDefault: translation.languageCode === portalLang,
                languageRef: translation.languageCode,
                value: translation.name,
            });
        });
    }
    return {
        id: label.id,
        facetId: facetId,
        dataType: dataType,
        executeSectionQuery: true,
        name: name,
        info: [
            {
                name: "name",
                value: name,
                cellContent: "text",
                specialWidth: "w-11/12",
            },
            {
                cellContent: "icon",
                value: label.id,
                evaluate: true,
                specialWidth: "w-0.5/12",
                isAction: true,
                modal: [NewLabel(label.id, label.code, translations, facetId)],
                name: "label",
                onClickAction: () => {
                    openModalVendureTranslations({
                        VARIABLES: { id: label.id },
                        QUERY: TYPE_QUERY.EDIT_LABEL,
                        title: `${i18n.t("edit-label")}`,
                    });
                },
                icon: {
                    name: "edit",
                    id: label.id,
                    values: label.id,
                    itemType: dataType,
                },
            },
            {
                cellContent: "icon",
                value: label.id,
                evaluate: true,
                specialWidth: "w-0.5/12",
                isAction: true,
                modal: DeleteLabel(label.id, name),
                name: "label",
                icon: {
                    name: "delete",
                    values: label.id,
                    itemType: dataType,
                },
            },
        ],
    };
};

export default Label;
