import { useEffect, useState, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import GetSalesTablesHeaders from "../../Utils/Table/Headers/GetSalesTableHeaders";
import { normalizeToSearch } from "../../Utils/Utils";
import AssignedUser from "./models/AssignedUser";

import {
    setBatchActive,
    setCheckable,
    setHeaders,
    setSearchable,
    setTableResults,
    setLoading,
    setCount,
    setItemsPerpage,
    cleanCheckedItems,
} from "../../../actions/tableActions";
import { SalesContextDeprecated } from "contexts/Sales";

const SECTION_NAME = "list-assigned-users";

const ListAssignedUsers = () => {
    const dispatch = useDispatch();
    const [usersData, setUsersData] = useState([]);
    const [copyUsersData, setCopyUsersData] = useState([]);
    const { data: dataContext } = useContext(SalesContextDeprecated);
    const initialUsersRender = useRef(true);
    const [users, setUsers] = useState([]);

    const { currentPage, perPage, sortCriteria, refreshData, activeFilters } = useSelector((state) => state.table);
    const search = activeFilters?.search ? activeFilters.search : "";

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        dispatch(setHeaders(GetSalesTablesHeaders(SECTION_NAME)));
        dispatch(setCheckable(true));
        dispatch(setSearchable(true));
        dispatch(setBatchActive(BatchActions()));
        dispatch(setLoading(true));
        dispatch(setItemsPerpage(8));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (dataContext?.shops?.results?.[0]?.usersInfo) {
            const fieldsData = getFieldsFromData(dataContext.shops.results[0].usersInfo);

            if (!sortCriteria && !search && !initialUsersRender.current) {
                setUsersData(fieldsData);
            } else if (!sortCriteria && search && !initialUsersRender.current) {
                setUsersData(filterSearch(fieldsData, search));
            } else if (sortCriteria && !search && !initialUsersRender.current) {
                handlerSortCriteria(sortCriteria, fieldsData);
            } else if (sortCriteria && search && !initialUsersRender.current) {
                handlerSortCriteria(sortCriteria, filterSearch(fieldsData, search));
            }

            setCopyUsersData(fieldsData);
            // dispatch(setLoading(false));
            dispatch(cleanCheckedItems(true));
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [dataContext]);

    useEffect(() => {
        setUsers(getUsersForPage(usersData, currentPage, perPage));
        dispatch(setCount(usersData.length));
        // eslint-disable-next-line
    }, [usersData]);

    useEffect(() => {
        if (usersData.length > 0 && !initialUsersRender.current) {
            setUsers(getUsersForPage(usersData, currentPage, perPage));
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        if (sortCriteria && !search && !initialUsersRender.current) {
            dispatch(setLoading(false));
            handlerSortCriteria(sortCriteria, usersData);
        } else if (sortCriteria && search && !initialUsersRender.current) {
            dispatch(setLoading(false));
            handlerSortCriteria(sortCriteria, filterSearch(copyUsersData, search));
        } else if (!sortCriteria && !search && !initialUsersRender.current) {
            dispatch(setLoading(false));
            setUsersData(copyUsersData);
        } else if (!sortCriteria && search && !initialUsersRender.current) {
            dispatch(setLoading(false));
            setUsersData(filterSearch(copyUsersData, search));
        }
        // eslint-disable-next-line
    }, [sortCriteria]);

    useEffect(() => {
        dispatch(setTableResults(arrangeData(users, copyUsersData)));
        initialUsersRender.current = false;
        // eslint-disable-next-line
    }, [users]);

    useEffect(() => {
        if (search && !sortCriteria && !initialUsersRender.current) {
            setUsersData(filterSearch(copyUsersData, search));
        } else if (search && sortCriteria && !initialUsersRender.current) {
            handlerSortCriteria(sortCriteria, filterSearch(copyUsersData, search));
        } else if (!search && sortCriteria) {
            handlerSortCriteria(sortCriteria, copyUsersData);
        } else if (!search && !sortCriteria && !initialUsersRender.current) {
            setUsersData(copyUsersData);
        }
        // eslint-disable-next-line
    }, [search]);

    const arrangeData = (data, copyUsersData) => {
        const users = [];

        if (data) {
            data.forEach((dataItem) => {
                users.push(AssignedUser(dataItem));
            });
        }

        return users;
    };

    const getFieldsFromData = (data) => {
        const arrData = [];
        data.forEach((element) => {
            if (element.id >= 0 && element.fullName && element.accountname) {
                arrData.push({ id: element.id, name: element.fullName, email: element.accountname });
            }
        });

        return arrData;
    };

    const getUsersForPage = (users, currentPage, itemsPerPage) => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return users.slice(startIndex, endIndex);
    };

    const handlerSortCriteria = (sortCriteria, users) => {
        const field = sortCriteria.match(/field:"([^"]+)"/) ? sortCriteria.match(/field:"([^"]+)"/)[1] : null;
        const criteria = sortCriteria.match(/criteria:"([^"]+)"/) ? sortCriteria.match(/criteria:"([^"]+)"/)[1] : null;

        if (field === "email") {
            if (criteria === "asc") {
                setUsersData(sortUsersAlphabetically(users, false, "email"));
            } else if (criteria === "desc") {
                setUsersData(sortUsersAlphabetically(users, true, "email"));
            }
        } else if (field === "name") {
            if (criteria === "asc") {
                setUsersData(sortUsersAlphabetically(users, false, "name"));
            } else if (criteria === "desc") {
                setUsersData(sortUsersAlphabetically(users, true, "name"));
            }
        }
    };

    const sortUsersAlphabetically = (objects, ascending, property) => {
        const sortedObjects = [...objects]; // make a copy of the original array
        sortedObjects.sort((a, b) => {
            const propertyA = a[property].toLowerCase();
            const propertyB = b[property].toLowerCase();
            if (ascending) {
                if (propertyA < propertyB) {
                    return -1;
                }
                if (propertyA > propertyB) {
                    return 1;
                }
                return 0;
            } else {
                if (propertyA > propertyB) {
                    return -1;
                }
                if (propertyA < propertyB) {
                    return 1;
                }
                return 0;
            }
        });
        return sortedObjects;
    };

    const filterSearch = (usersData, searchQuery) => {
        const copyUsersArr = [...usersData];

        if (searchQuery) {
            return copyUsersArr.filter(
                (user) =>
                    normalizeToSearch(user.name).includes(normalizeToSearch(searchQuery.toLowerCase())) ||
                    normalizeToSearch(user.email).includes(normalizeToSearch(searchQuery.toLowerCase()))
            );
        } else {
            return copyUsersArr;
        }
    };

    return null;
};

export default ListAssignedUsers;

const BatchActions = () => {
    return [
        {
            name: "delete",
            action: "delete-assigned-users",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "delete-users-confirm",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "delete", style: "red", action: "delete-assigned-users" },
            ],
        },
    ];
};
