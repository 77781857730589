import React, { useRef, cloneElement } from "react";
import classNames from "classnames";

import Button from "components/Button";
import Select from "components/Select";

/**
 * @params {string} id - The id of the DropdownButton
 * @param {Array} options - The options of the DropdownButton
 * @params {JSX.Element} children - The children of the DropdownButton (if options is not provided)
 * @param {string} label - The label of the DropdownButton (default option)
 * @param {boolean} disabled - The disabled state of the DropdownButton
 * @param {string} design The design of the button (blue, blue-outline)
 * @returns
 */
const DropdownButton = (props) => {
    if (!props?.id) {
        throw new Error("DropdownButton requires an id prop");
    }

    const dropdownRef = useRef(null);

    const items = props?.options || props?.children;
    const defaultOption = props?.label || (Array.isArray(items) ? items?.[0] : items);
    const others = props?.label ? items : Array.isArray(items) ? items?.slice(1) : null;
    const design = props?.design ?? "blue";

    const designClass = {
        dropdown: classNames({
            "btn-blue text-white": design === "blue",
            "btn-white btn-blue-outline font-bold text-zafiro-600": design === "blue-outline",
        }),
        handler: "w-auto px-4 py-1 rounded",
        handlerIcon: classNames({
            "border-l px-3 py-1": true,
            "text-white": design === "blue",
            "text-zafiro-600": design === "blue-outline",
        }),
    };

    const disabled = props?.disabled;

    const parseOption = (option, isDefault = false) => {
        const customProps = {
            disabled,
            onClick: () => {
                if (dropdownRef.current) {
                    dropdownRef.current.close();
                }
            },
        };

        if (typeof option === "string") {
            return <div {...customProps}>{option}</div>;
        }

        if (React.isValidElement(option)) {
            return cloneElement(option, {
                // if the option is not disabled, use dropdown disabled prop
                disabled: option?.props?.disabled || customProps.disabled,
                onClick: (...args) => {
                    if (option?.props?.onClick) {
                        option.props.onClick(...args);
                    }
                    customProps.onClick(...args);
                },
            });
        }
        if (typeof option === "object") {
            const label = option?.label || "";
            const optionProps = {
                ...option,
                // remove label prop from option (label is rendered as children)
                label: undefined,
                // use custom class if provided, otherwise use the option's class
                className: customProps.className || option?.className,
                // if the option is not disabled, use dropdown disabled prop
                disabled: option?.disabled || customProps.disabled,
                onClick: (...args) => {
                    if (option?.onClick) {
                        option.onClick(...args);
                    }
                    customProps.onClick(...args);
                },
            };
            if (isDefault) {
                return <DefaultOption {...optionProps}>{label}</DefaultOption>;
            }
            return <Option {...optionProps}>{label}</Option>;
        }
        throw new Error("Invalid option provided to DropdownButton");
    };

    return (
        <Select
            {...{
                ...props,
                id: props?.id,

                value: "default",
                ref: dropdownRef,
                showArrow: others?.length > 0,
                designClass,
                placeholder: parseOption(defaultOption, true),
            }}
        >
            {others?.length > 0 ? (
                <div className="flex flex-col items">{others.map((option) => parseOption(option))}</div>
            ) : null}
        </Select>
    );
};
DropdownButton.displayName = "DropdownButton";

/**
 * @param id {string}
 * @param disabled {boolean}
 * @param onClick {function}
 * @param href {string}
 * @param className {string}
 * @param children {React.Children}
 * @returns {JSX.Element}
 * @constructor
 */
export const Option = (props) => {
    const children = props?.children;

    let buttonProps = {
        design: "link",
        ...props,
        className: classNames({
            "px-4 py-2": true,
            [props?.className]: props?.className,
        }),
    };
    delete buttonProps?.children;

    return (
        <Button design="link" {...buttonProps}>
            {children}
        </Button>
    );
};

export const DefaultOption = (props) => {
    return <Button {...{ ...props, value: "default" }} />;
};

export default DropdownButton;
